<template>
  <div class="">
      <div id="basic-info" class="card mt-4 blur-dark-fade">
      <div class="p-4 fs-5">
          <h5>{{ $t('sideNav.dashboard') }}</h5>
      </div>
      <div class="card-body pt-0">
          <div class="row">
          <div class="col-12">
              <label class="form-label text-danger">{{ $t('signUp.fullname')}}</label>
              <input v-model="profile.full_name" class="form-control"  placeholder="Full name" type="text" />
          </div>
          </div>
          <div class="row">
          <!-- <div class="col-12 col-md-6">
              <label class="form-label mt-2">อีเมล์</label>
              <soft-input id="email" type="email" placeholder="example@email.com" />
          </div> -->
          <div class="col-12">
              <label class="form-label mt-2 text-danger">{{ $t('signUp.email')}}</label>
              <input v-model="profile.email" class="form-control"  placeholder="email@example.com" type="email">
          </div>
          </div>
          <div class="row">
          <div class="col-12">
            <label class="form-label mt-2 text-danger">{{ $t('signUp.phone')}}</label>
            <input v-model="profile.mobile" class="form-control"  placeholder="Mobile number" type="mobile">
          </div>
          </div>
          <soft-button
          class="float-end mt-6 mb-0"
          variant="outline"
          color="danger"
          size="md"
          @click="updateMemberProfile()"
          >{{ $t('dashboard.update') }}</soft-button
          >
      </div>
      </div>
      <div v-if="getMemberProfile && getMemberProfile.token_google == null && getMemberProfile.token_line == null && getMemberProfile.token_facebook == null" id="password" class="card mt-4 blur-dark-fade">
          <h5 class="p-4 fs-5">{{ $t('dashboard.changePassword') }}</h5>
      <div class="card-body pt-0">
          <!-- <div class="">
            <label class="form-label">รหัสผ่านปัจจุบัน</label>
            <input v-model="change_pass.old_pass" class="form-control"  placeholder="รหัสผ่านปัจจุบัน" type="password">
            <span v-if="change_pass.password && change_pass.confirm_pass && !change_pass.old_pass" class="text-danger text-sm">กรุณากรอก รหัสผ่านปัจจุบัน</span>
            <span v-if="pass_error" class="text-danger text-sm">รหัสผ่านปัจจุบัน ไม่ถูกต้อง</span>
          </div> -->

          <div class="">
            <label class="form-label text-danger">{{ $t('dashboard.newPassword') }}</label>
            <input v-model="change_pass.password" class="form-control"  :placeholder="$t('dashboard.newPassword')" type="password">
          </div>
          
          <div class="">
            <label class="form-label text-danger">{{ $t('dashboard.confirmPassword') }}</label>
            <input v-model="change_pass.confirm_pass" class="form-control"  :placeholder="$t('dashboard.confirmPassword')" type="password">
            <span v-if="change_pass.password && change_pass.confirm_pass && change_pass.password != change_pass.confirm_pass" class="text-danger text-sm">กรุณายืนยันรหัสผ่านใหม่ ให้ตรงกัน</span>
          </div>
          
          <h5 class="mt-5">{{ $t('passwordInstructions.title') }}</h5>
          <p class="text-muted mb-2 text-danger">
            {{ $t('passwordInstructions.subtitle') }}
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
          <li>
              <span class="text-sm">{{ $t('passwordInstructions.rules[0]') }} </span>
          </li>
          <li>
              <span class="text-sm">{{ $t('passwordInstructions.rules[1]') }} </span>
          </li>
          <li>
              <span class="text-sm">{{ $t('passwordInstructions.rules[2]') }} </span>
          </li>
          <li>
              <span class="text-sm">{{ $t('passwordInstructions.rules[3]') }}</span>
          </li>
          </ul>
          <soft-button
          class="float-end mt-6 mb-0"
          variant="outline"
          color="danger"
          size="md"
          @click="saveChangePassword()"
          :disabled="change_pass.password && change_pass.confirm_pass && change_pass.password != change_pass.confirm_pass"
          > {{ $t('passwordInstructions.confirmButton') }}</soft-button
          >
      </div>
      </div>
      <div id="delete" class="card mt-4 blur-dark-fade">
      <div class="p-4 fs-5">
        <h5>{{ $t('accountDeletion.title') }}</h5>
        <p class="text-sm mb-0">
          {{ $t('accountDeletion.description') }}
          </p>
      </div>
      </div>
  </div>
</template>

<script>

import SoftButton from "@/components/SoftButton.vue";
import { mapGetters, mapActions } from "vuex";

import axios from 'axios';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://uatapi.goldenbug.co/api";

export default {
  name: "MemberProfileEdit",
  components: {
    SoftButton,
  },
  data() {
    return {
      profile: {
        member_id: '',
        full_name: '',
        email: '',
        mobile: '',
      },
      change_pass: {
        member_id: '',
        old_pass: '',
        password: '',
        confirm_pass: '',
      },
      pass_error: false,
      token: window.localStorage.getItem('jcr_token') || '',
    };
  },
  async created() {
      this.fetchOrders();
    },
    mounted() {
      setTimeout(() => {
      this.checkLogin();
      this.change_pass.member_id = this.getMemberProfile?._id
      this.profile.member_id = this.getMemberProfile?._id
      this.profile.full_name = this.getMemberProfile?.full_name
      this.profile.email = this.getMemberProfile?.email
      this.profile.mobile = this.getMemberProfile?.mobile
    }, 200);

  },
  computed: {
    ...mapGetters(['orders', 'getMemberProfile']),
  },
  methods: {
    ...mapActions(['fetchOrders', 'getProfile', 'getSetting']),
    async checkLogin() {
      const checkLogin = typeof this.getMemberProfile !== 'undefined';
      // console.log(this.getMemberProfile);
      if (!checkLogin) {
        await this.$store.dispatch('logOut');
        return this.$router.push('/signin');
      }
    },
    async updateMemberProfile() {
      if (this.getMemberProfile.p) {
        
      }
      this.$swal({
        title: "ยืนยันการแก้ไขโปรไฟล์ ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "ปิด",
        confirmButtonText: "ยืนยัน แก้ไขโปรไฟล์",
        customClass: {
          confirmButton: "btn bg-danger",
          cancelButton: "btn bg-dark",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            axios.defaults.headers.common['Authorization'] = this.token;
            await axios.post(`${API_URL}/member/editMember`, this.profile,
            { headers: { 'Content-Type': 'application/json' } });
            this.$swal({
              icon: "success",
              title: "สำเร็จ",
              text: "แก้ไขโปรไฟล์เรียบร้อยแล้ว",
              type: 'success',
            });
            this.getProfile()
          } catch (error) {
            console.log('An error occurred while updating profile: ', error);
          }
        } else if (
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });
    },
    async saveChangePassword() {
      this.$swal({
        title: "ยืนยันการ เปลี่ยนรหัสผ่าน ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "ปิด",
        confirmButtonText: "ยืนยัน เปลี่ยนรหัสผ่าน",
        customClass: {
          confirmButton: "btn bg-danger",
          cancelButton: "btn bg-dark",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            axios.defaults.headers.common['Authorization'] = this.token;
            await axios.post(`${API_URL}/member/editMember`, this.change_pass,
            { headers: { 'Content-Type': 'application/json' } });
            this.$swal({
              icon: "success",
              title: "สำเร็จ",
              text: "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
              type: 'success',
            });
            this.getProfile()
          } catch (error) {
            console.log('An error occurred while updating profile: ', error);
          }
        } else if (
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
  * {
      color: #e3e3e3;
      font-size: 14px;
  }
  @media (max-width: 500px) {
    *{
      font-size: 12px !important;
    }
    .text-sm{
      font-size: 12px !important;
    }
    .form-control{
      padding: 0.5rem !important;
    }
  }
</style>