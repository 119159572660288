<template>
  <navbar :is-blur="true" :dark-mode="true" />
  
  <div class="container mt-7">
    <div class="row">
      <div class="col-lg-7 text-center mx-auto mt-6">
        <h1 class="text-white fs-3">{{ $t('giftCode.redeemTitle') }}</h1>
      </div>
    </div>
  </div>

  <div class="container mt-2">
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <div class="card blur-dark-fade mt-4">
          <div class="card-body p-5">
            <div class="row d-flex">
              <div class="col-4 col-md-3 d-flex justify-content-center align-items-center">
                <div class="gift-icon pulse"></div>
              </div>
              <div class="col-8 col-md-9">
                <p class="text-white mb-3" v-html="$t('giftCode.description')"></p>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="input-group input-group-lg mb-3">
                  <input 
                    type="text" 
                    class="form-control bg-gray-800 text-white border-0" 
                    :placeholder="$t('giftCode.placeholder')"
                    v-model="redeemCode"
                    @input="formatRedeemCode"
                  >
                  <button 
                    class="btn btn-primary pulse-button m-0" 
                    type="button" 
                    @click="validateAndConfirmRedeem"
                  >
                    {{ $t('giftCode.redeem') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SideNav from "@/views/pages/Account/components/SideNav.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "RedeemMember",
  components: {
    Navbar,
    SideNav,
    AppFooter
  },
  data() {
    return {
      moment: moment,
      redeemCode: ""
    }
  },
  created() {
    this.getHistoryTopupCredit();
    this.checkLogin();
  },
  computed: {
    ...mapGetters(['games', 'gameBanner', 'getSetting', 'banks', 'getMemberProfile', 'topupTransaction', 'getMemberProfile' ,'giftCard']),
    reversedTopupTransaction() {
      return this.topupTransaction ? this.topupTransaction.slice().reverse() : [];
    }
  },
  methods: {
    ...mapActions(['fetchBanks', 'getHistoryTopupCredit', 'useGiftCard']),
    async checkLogin() {
      const checkLogin = typeof this.getMemberProfile !== 'undefined';
      if (!checkLogin) {
        await this.$store.dispatch('logOut');
        return this.$router.push('/signin');
      }
    },
    formatRedeemCode() {
      this.redeemCode = this.redeemCode.replace(/[^\w]/g, '').replace(/(.{4})/g, '$1-').slice(0, 14);
    },
    validateAndConfirmRedeem() {
      this.redeemCode = this.redeemCode.replace(/[^\w]/g, '');

      if (!this.redeemCode) {
        this.$swal({
          icon: "warning",
          title: this.$t('giftCode.invalidCode'),
          text: this.$t('giftCode.enterCode'),
        });
        return;
      } 
      if (this.redeemCode.length !== 10) {
        this.redeemCode = "";
        this.$swal({
          icon: "warning",
          title: this.$t('giftCode.invalidCode'),
          text: this.$t('giftCode.invalidFormat'),
        });
        return;
      }

      this.$swal({
        icon: "warning",
        title: this.$t('giftCode.confirmTitle'),
        text: this.$t('giftCode.confirmText'),
        showCancelButton: true,
        confirmButtonText: this.$t('giftCode.confirmButton'),
        cancelButtonText: this.$t('giftCode.cancelButton'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.redeemGiftCode();
        }
      });
    },
    async redeemGiftCode() {
      this.redeemCode = this.redeemCode.replace(/[^\w]/g, '');

      try {
        const response = await this.$store.dispatch('useGiftCard', { code_gift_card: this.redeemCode });

        if (response && response.success) {
          this.redeemCode = ""
          this.$swal({
            icon: "success",
            title: this.$t('giftCode.successTitle'),
            text: this.$t('giftCode.successText'),
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/");
            }
          });
        } else {
          this.redeemCode = ""
          this.$swal({
            icon: "error",
            title: this.$t('giftCode.errorTitle'),
            text: response.message || this.$t('giftCode.errorText'),
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/member/redeem");
            }
          });
        }
      } catch (error) {
        console.error("เกิดข้อผิดพลาดขณะแลกรับคูปอง:", error);
        this.redeemCode = ""
        this.$swal({
          icon: "error",
          title: this.$t('giftCode.errorTitle'),
          text: this.$t('giftCode.errorText'),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/member/redeem");
          }
        });
      }
    }
  }
};
</script>

<style scoped>

.gift-icon {
  width: 80px;
  height: 80px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20 6h-2.18c.11-.31.18-.65.18-1a2.996 2.996 0 0 0-5.5-1.65l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z"/></svg>') no-repeat center center;
  background-size: contain;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.btn-primary {
  background: linear-gradient(45deg, #c80085, #ff56b8);
  border: none;
  transition: background 0.3s ease;
}

.btn-primary:hover {
  background: linear-gradient(45deg, #ff56b8, #c80085);
}
*{
  font-size: 14px;
}

@media screen and (max-width: 425px) {
  .col-md-3{
    width: 100% !important;
  }
  .col-md-9{
    width: 100% !important;
    margin-top: 1rem !important;
  }
}
@media (max-width: 500px) {
  *{
    font-size: 12px;
  }
  .btn.btn-primary{
    font-size: 12px !important;
  }
}

</style>