<template>
  <navbar :is-blur="true" :dark-mode="true" />
      <div class="container-fluid px-0 mt-8">
        <div class="container mt-4">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="title-highlight">
                <h1 class="text-white fs-5">{{ $t('menu.cashCard') }}</h1>
              </div>
            </div>
          </div>
          <div class="container px-0">
            <h2 class="ms-2 text-white fw-normal" style="font-size: 14px;" v-html="$t('gameServiceTitle')"></h2>
            <p class="ms-2 text-white" style="font-size: 14px;">{{ $t('gameServiceDescription') }}</p>
            <div class="d-flex justify-content-between gap-2 flex-wrap flex-md-nowrap home-tab">
              <div class="d-flex justify-content-start gap-3 overflow-scroll text-nowrap flex-nowrap w-100">
                <div class="" v-for="(item, index) in postStatusList" :key="index">
                  <button
                    class="btn mobile-button"
                    style="font-size: 0.875rem;"
                    :class="
                      postStatus == item.status ? 'btn-danger' : 'btn-outline-danger'
                    "
                    @click="postStatus = item.status"
                  >
                    <span
                      ><i v-if="item.icon" :class="item.icon"></i>
                      {{ $t(`homepage.${item.text}`) }}</span
                    >
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-4">
                <input class="form-control p-2 " type="text" v-model="searchInput" :placeholder="$t('homepage.searchGame')"/>
              </div>
            </div>
          </div>
        
          <div class="" v-if="cardFilterNormal && cardFilterNormal.length && !isMobile">
            <div class="row justify-content-start">
                <div class="col-12 mb-3">
                  <div class="row">
                    <div
                      v-for="(game, index) in cardFilterNormal"
                      :key="index"
                      class="col-lg-2 col-md-3 col-4 mb-4"
                      >
                      <div
                        class="card card-game d-flex flex-column position-relative cursor-pointer zoom-product"
                        @click="goToBuy(game)"
                      >
                        <span
                          class="badge badge-success animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                          v-if="game.post_status && game.post_status === 1"
                          >new</span
                        >
                        <span
                          class="badge badge-danger animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                          v-if="game.post_status && game.post_status === 2"
                          >hot</span
                        >
                        <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="game-icon img-fluid w-100 h-100 mb-2" :alt="`รับเติมเกม ${ game.name }`"> -->
                        <img
                          v-lazy="game.icon_url"
                          class="game-icon img-fluid w-100 h-100 mb-2"
                          :alt="`รับเติมเกม ${game.name}`"
                        />
                  <div class="card-body d-flex flex-column flex-grow-1 p-0">
                    <div
                      class="d-flex justify-content-center align-items-center h-100"
                      style="min-height: 44px"
                    >
                      <strong class="title text-sm text-white text-center">
                        {{ game.name }}
                      </strong>
                    </div>
                    <div class="text-center w-100 mt-2">
                      <router-link
                        data-scroll
                        :to="`/game-topup/${formatText(game.name)}-${game._id}`"
                              class="btn btn-sm btn-danger w-100"
                              >{{ $t('homepage.choose') }}</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        
        <!-- If no games -->
        <div v-if="gamesTopupAuto.length == 0">
          <div class="mb-4">
              <div class="row justify-content-center">
                  <div class="col text-center px-2">
                      <div class="card blur-dark-fade p-5">
                          <h4>{{ $t('homepage.notFoundGame') }}</h4>
                      </div>
                  </div>
              </div>
          </div>
        </div> 
        </div>
      </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";


export default {
  name: "CashCard",
  components: {
    Navbar,
    AppFooter,

  },
  data() {
    return {
      searchInput: '',
      postStatusList: [
        {
          status: '',
          text: "all",
          icon: 'fas fa-th',
        },
        {
          status: 1,
          text: "new",
          icon: "fas fa-meteor",
        },
        {
          status: 2,
          text: "hot",
          icon: 'fas fa-fire',
        },
      ],
      postStatus: '',
      itemsPerPage: 6,
      displayedItems: 6
    }
  },
  async created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    await this.fetchGames();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  computed: {
    ...mapGetters(['games', 'gamesTopupAuto',  'getSetting', 'isLoggedIn']),
    cardFilterNormal() {
      let filterCard = this.gamesTopupAuto.filter(
        (game) => game.product_type == 2
      );
      if (this.searchInput) {
        if (this.postStatus) {
          return filterCard.filter((game) => {
            return (
              game.name
                .toLowerCase()
                .includes(this.searchInput.toLowerCase()) &&
              game.post_status == this.postStatus
            );
          });
        }
        return filterCard.filter((game) => {
          return game.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase());
        });
      }
      if (this.postStatus) {
        return filterCard.filter((game) => {
          return game.post_status == this.postStatus;
        });
      }
      return filterCard;
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['fetchGames']),
    loadMoreNormal() {
      this.displayedItems += this.itemsPerPage
      if (this.displayedItems > this.cardFilterNormal.length) {
        this.displayedItems = this.cardFilterNormal.length
      }
    },
    formatText(gameName) {
      return gameName.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();  ;
    },
    goToBuy(param) {
      const sanitizedGameName = param.name.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();   
      const formatText = `${sanitizedGameName}-${param._id}`;
      
      return this.$router.push({
        name: "GameTopUpSingle",
        params: { game_id: formatText },
      });
    },
  }
};
</script>

<style lang="scss">
  .home-tab {
    .btn-outline-danger {
      background-color: #2c2c2c;
      border: none;
      &:hover {
        background-color: #2c2c2c !important;
        transform: unset !important;
        opacity: unset !important;
      }
    }
    padding: 0px 24px;
  }
  .game-icon{
    width: 100%;
  }
  .zoom-product {
    overflow: hidden;
    position: relative;
    transition: transform 0.5s ease;
    }
  
    .zoom-product:before,
    .zoom-product:after {
      content: "";
      position: absolute;
      top: -50%;
      left: -100%;
      width: 60%;
      height: 200%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: rotate(25deg);
      z-index: 1;
    }
  
    .zoom-product:before {
      transition: all 1.2s ease;
    }
  
    .zoom-product:after {
      transition: all 1.2s ease;
      transition-delay: 0.4s;
    }
    .zoom-product:hover:before,
    .zoom-product:hover:after {
      left: 130%;
    }
  
    .zoom-product:hover {
      transform: scale(1.03);
    }
  
    .zoom-product .btn {
      position: relative;
      z-index: 2;
      transition: all 0.3s ease;
    }
  
    .zoom-product:hover .btn {
      background-color: #dc3545;
      color: white;
    }
    .game-icon {
      width: 100%;
      height: 200px; /* กำหนดความสูงของรูปภาพ */
      object-fit: cover;
    }
    .title{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }
    .zoom-product {
      strong {
          font-size: 1rem !important;
          font-weight: 500;
          letter-spacing: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          padding: 0 10px;
      }
    }
    @media (max-width: 768px) {
      h1.fs-5 {
        font-size: 16px !important;
      }
      h2 {
        font-size: 12px !important;
      }
      p {
        font-size: 12px !important;
      }
      .home-tab {
        padding: 0 !important;
      }
    }
</style>