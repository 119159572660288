<template>
  <navbar :is-blur="true" :dark-mode="true" />
  
  <div class="container-fluid px-0 main my-8" :style="`--jcr-main-bgcolor: ${getSetting.page.home_page.bgcolor};`">
    <div class="j-space my-3"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <h1 class="text-center text-white mb-4">{{ $t('signUp.signup') }}</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
          <div class="card z-index-1 blur-dark-fade">
            <div class="card-body">
              <p class="text-danger fw-bold" style="font-size: 14px;">{{ $t('signUp.paragraph') }}</p>
              <form role="form" class="text-start" @submit.prevent="register">
                <div class="mb-3">
                  <input
                    id="phone"
                    class="form-control"
                    type="text"
                    :placeholder="$t('signUp.username')"
                    name="username"
                    v-model="user.username"
                  />
                </div>
                <div class="mb-3">
                  <input
                    id="password"
                    class="form-control"
                    name="password"
                    type="password"
                    :placeholder="$t('signUp.password')"
                    v-model="user.password"
                  />
                </div>
                <div class="mb-3">
                  <input
                    id="full_name"
                    class="form-control"
                    type="text"
                    :placeholder="$t('signUp.fullname')"
                    name="full_name"
                    v-model="user.full_name"
                  />
                </div>
                <div class="mb-3">
                  <input
                    id="mobile"
                    class="form-control"
                    type="tel"
                    :placeholder="$t('signUp.phone')"
                    name="mobile"
                    v-model="user.mobile"
                  />
                </div>
                <div class="mb-3">
                  <input
                    id="email"
                    class="form-control"
                    type="email"
                    :placeholder="$t('signUp.email')"
                    name="email"
                    v-model="user.email"
                  />
                </div>
                <div class="text-center">
                  <soft-button
                    class="my-4 mb-3 text-lg"
                    color="danger"
                    full-width
                    >{{ $t('signUp.signup') }}
                  </soft-button>
                </div>
                <!-- <div class="mb-2 text-center position-relative">
                  <p
                    class="px-3 mb-2 text-sm bg-white font-weight-bold text-secondary text-border d-inline z-index-2"
                  >
                    หรือ
                  </p>
                </div> -->
              </form>
              <div class="text-center">
                <button
                  class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
                  type="button"
                  @click="redirectToLineLogin"
                  ><i class="fab fa-line"></i>{{ $t('signUp.signupLine') }}
                </button>
                <button
                    class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
                    type="button"
                    @click="redirectToGoogleLogin"
                    ><i class="fab fa-google"></i> {{ $t('signUp.signupGoogle') }}
                  </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";

import { store, mapMutations, mapGetters } from "vuex";
import axios from 'axios';
const COMPANY_KEY = "66d6f64c5543840d390f24b4";


export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    SoftButton,

  },
  data() {
    return {
      user: {
        username: '',
        password: '',
        full_name: '',
        mobile: '',
        email: '',
        company_id: COMPANY_KEY
      },
      remember_login: false
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push('/profile');
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getSetting']),
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    redirectToLineLogin() {
      const clientId = '2004893434'; // Replace with your LINE Channel ID
      const redirectUri = encodeURIComponent('https://termgame.goldenbug.co/auth/line');
      const state = 'someRandomState'; // This should be randomly generated for security reasons
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=profile`;
    },
    redirectToGoogleLogin() {
      const clientId = '31055649956934-4cqqoel3sa26m2p7kpghcqhpedfvkp1m.apps.googleusercontent.com'; // Replace with your LINE Channel ID
      const redirectUri = encodeURIComponent('https://termgame.goldenbug.co/auth/google');
      window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/userinfo.email&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    },
    register() {
      let data = new URLSearchParams();
      // console.log(this.user)
      data.append('username', this.user.username);
      data.append('password', this.user.password);
      data.append('full_name', this.user.full_name);
      data.append('mobile', this.user.mobile);
      data.append('email', this.user.email);
      data.append('company_id', this.user.company_id);
      
      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.post(`${this.$store.state.defaultStore.host_api}/member/register`, data)
        .then(response => {
          // Handle the response data using a function
          if (!response.data.success) {
            if (response.data.datas) {
              var htmlString = '<ul style="list-style: none">' + response.data.datas.map(function(item) {
                  return '<li>' + item + '</li>';
              }).join('') + '</ul>';
              return this.$swal({
                icon: "warning",
                title: response.data.message,
                html: `${htmlString}`,
                type: 'warning',
              });
            }
            return this.$swal({
              icon: "warning",
              title: response.data.message,
              type: 'warning',
            });
          }

          this.$swal({
            icon: "success",
            title: 'สมัครสมาชิกเรียบร้อยแล้ว',
            type: 'success',
          });

          this.login(this.user.username, this.user.password)

        })
        .catch(error => {
          console.error(error);
        });
    },
    login(username, password) {
      let data = new URLSearchParams();
      data.append('username', username);
      data.append('password', password);

      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.post(`${this.$store.state.defaultStore.host_api}/auth/login`, data)
        .then(response => {
          if (!response.data.success) {
            return this.$swal({
              icon: "warning",
              title: 'ข้อมูลไม่ถูกต้อง',
              type: 'warning',
            });
          }
          // Handle the response data using a function
          const TOKEN = response.data.datas.tokenData ?? false;
          if (!TOKEN) {
            console.log('error');
            return false;
          }
          this.$store.dispatch('loginSuccess', TOKEN.token);
          window.localStorage.setItem('jcr_token', TOKEN.token)

          const selectedItem = JSON.parse(window.localStorage.getItem('selectedItem'));
          if (selectedItem && selectedItem.length) {
            const game_id = selectedItem[0].game_id._id;
            return window.location.href = `/game-topup/${game_id}`;
          }
          return window.location.href = `/`;    
          // window.location.href = `/${this.getSetting.member_landing ? this.getSetting.member_landing : ''}`;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
};
</script>


<style scoped lang="scss">
  .form-check-label {
    margin-bottom: 0;
  }
</style>