import axios from 'axios';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://uatapi.goldenbug.co/api";


export default {
  state() {
    return {
      products: [],
      product: null
    }
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setProduct(state, product) {
      state.product = product;
    },
    addProduct(state, product) {
      state.products.push(product);
    },
    updateProduct(state, updatedProduct) {
      const index = state.products.findIndex(product => product._id === updatedProduct._id);
      if (index !== -1) {
        state.products[index] = updatedProduct;
      }
    },
    removeProduct(state, productId) {
      state.products = state.products.filter(product => product._id !== productId);
    }
  },
  actions: {
    async fetchProducts({ commit }, gameId) {
      try {
        const response = await axios.post(`${API_URL}/product/getList`, JSON.stringify({ game_id: gameId }),
        { headers: { 'Content-Type': 'application/json' } });
        commit('setProducts', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching products: ', error);
      }
    },
    async fetchProductsById({ commit }, productId) {
      try {
        const response = await axios.post(`${API_URL}/product/getList`, JSON.stringify({ product_id: productId }),
        { headers: { 'Content-Type': 'application/json' } });
        commit('setProduct', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching products: ', error);
      }
    },
    async createProduct({ commit }, productData) {
      try {
        const response = await axios.post(`${API_URL}/products`, productData);
        commit('addProduct', response.data);
      } catch (error) {
        console.log('An error occurred while creating the product: ', error);
      }
    },
    async updateProduct({ commit }, productData) {
      try {
        const response = await axios.put(`${API_URL}/products/${productData._id}`, productData);
        commit('updateProduct', response.data);
      } catch (error) {
        console.log('An error occurred while updating the product: ', error);
      }
    },
    async deleteProduct({ commit }, productId) {
      try {
        await axios.delete(`${API_URL}/products/${productId}`);
        commit('removeProduct', productId);
      } catch (error) {
        console.log('An error occurred while deleting the product: ', error);
      }
    }
  },
  getters: {
    products(state) {
      return state.products;
    },
    product(state) {
      return state.product;
    }
  }
}