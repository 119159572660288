<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div class="page-header relative">
    <div class="container-fluid px-0 mt-7">
      <MainSliderHome :banners="this.getSetting.page?.home_page?.banners" />
    </div>
  </div>
  <!-- <button @click="resetModalTimer">Reset Modal Timer</button> -->

  <div v-if="this.cardFilterNormal.length > 0">
    <div class="container mt-3">
      <div class="row">
        <div class="col-6 col-md-6 col-lg-4">
          <div class="title-highlight">
            <strong class="text-white fs-5">{{ $t('menu.cashCard') }}</strong>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-8 text-end">
          <button class="btn btn-danger p-2 me-4" @click="$router.push('/cash-card')">{{ $t('homepage.viewMore') }}</button>
        </div>
      </div>
    </div>
  
    <div class="container" v-if="cardFilterNormal && cardFilterNormal.length">
      <Carousel
        ref="myCarousel"
        v-model="currentSlide"
        v-bind="settings"
        :breakpoints="breakpoints"
      >
        <Slide v-for="(card, index) in cardFilterNormal" :key="index">
          <div class="slide-con w-100 cash-card-con">
            <div
              class="card card-box shadow h-100 zoom-product"
              data-scroll
              href="#"
              @click="goToBuy(card)"
            >
              <div class="card-gradient h-100">
                <div class="row g-0 h-100" style="width: 100%">
                  <div class="col-4 col-md-4 h-100">
                    <!-- <img src="../../assets/img/curved-images/curved9.jpg" class="img-fluid rounded-start w-100 h-100 object-fit-cover" alt="" /> -->
                    <img
                      v-lazy="card.icon_url"
                      class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                      alt=""
                    />
                  </div>
                  <div class="col-8 col-md-8">
                    <div class="text-start ms-3 w-100 d-flex flex-column justify-content-center h-100">
                      <p class="card-title">{{ card.name }}</p>
                      <p class="card-text">{{ card.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
  
  <div v-if="this.gamesTopupNormal.length > 0">
    <div class="container mt-4">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="title-highlight">
            <strong class="text-white fs-5">{{ $t('menu.idPass') }}</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-2">
      <div
        class="d-flex justify-content-between gap-2 flex-wrap flex-md-nowrap home-tab"
      >
        <div
          class="d-flex justify-content-start gap-3 overflow-scroll text-nowrap flex-nowrap w-100"
        >
          <div class="" v-for="(item, index) in postStatusList" :key="index">
            <button
              class="btn mobile-button"
              :class="
                postStatusNormal == item.status
                  ? 'btn-danger'
                  : 'btn-outline-danger'
              "
              @click="postStatusNormal = item.status"
            >
              <span
                ><i v-if="item.icon" :class="item.icon"></i>
                {{ $t(`homepage.${item.text}`) }}</span
              >
            </button>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <input
            class="form-control p-2"
            type="text"
            v-model="searchInputNormal"
            :placeholder="$t('homepage.searchGame')"
          />
        </div>
      </div>
    </div>

    <div
      class="container px-4"
      v-if="gameFilterNormal && gameFilterNormal.length && !isMobile"
    >
      <div class="row justify-content-start">
        <div class="col-12 mb-3">
          <div class="row">
            <div
              v-for="(game, index) in displayedGamesNormal"
              :key="index"
              class="col-lg-2 col-md-3 col-6 px-2"
            >
              <div
                class="card card-game d-flex flex-column position-relative cursor-pointer zoom-product"
                @click="goToBuy(game)"
              >
                <span
                  class="badge badge-success animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status && game.post_status === 1"
                  >new</span
                >
                <span
                  class="badge badge-danger animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status && game.post_status === 2"
                  >hot</span
                >
                <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="game-icon img-fluid w-100 h-100 mb-2" :alt="`รับเติมเกม ${ game.name }`"> -->
                <img
                  v-lazy="game.icon_url"
                  class="game-icon img-fluid w-100 h-100 mb-2"
                  :alt="`รับเติมเกม ${game.name}`"
                />
                <div class="card-body d-flex flex-column flex-grow-1 p-0">
                  <div class="d-flex justify-content-center align-items-center h-100" style="min-height:44px;">
                    <strong class="title text-sm text-white text-center">
                      {{ game.name }}
                    </strong>
                  </div>
                  <div class="text-center w-100 mt-2">
                    <router-link
                      data-scroll
                      :to="`/game-topup/${formatText(game.name)}-${game._id}`"
                      class="btn btn-sm btn-danger w-100"
                      >{{ $t('homepage.choose') }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" v-if="gameFilterNormal.length > itemsPerPage">
            <div class="col-12 text-center">
              <button
                @click="loadMoreNormalDesktop"
                class="btn btn-danger"
                v-if="displayedGamesNormal.length < gameFilterNormal.length"
              >
                {{ $t('homepage.more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container px-4"
      v-if="gameFilterNormal && gameFilterNormal.length && isMobile"
    >
      <div class="row justify-content-start">
        <div class="col-12 mb-3">
          <div class="row">
            <div
              v-for="(game, index) in displayedGamesNormalMobile"
              :key="index"
              class="col-lg-2 col-md-3 col-4 mb-4"
            >
              <div
                class="card card-game d-flex flex-column position-relative cursor-pointer zoom-product"
                @click="goToBuy(game)"
              >
                <span
                  class="badge badge-success animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status === 1"
                  >new</span
                >
                <span
                  class="badge badge-danger animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status === 2"
                  >hot</span
                >
                <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="game-icon img-fluid w-100 h-100 mb-2" :alt="`รับเติมเกม ${ game.name }`" style="height: 100px !important;"> -->
                <img
                  v-lazy="game.icon_url"
                  class="game-icon img-fluid w-100 h-100 mb-2"
                  :alt="`รับเติมเกม ${game.name}`"
                  style="height: 100px !important;"
                />
                <div class="card-body d-flex flex-column flex-grow-1 p-0">
                  <div class="d-flex justify-content-center align-items-center h-100" style="min-height:44px;">
                    <strong class="title text-sm text-white text-center">
                      {{ game.name }}
                    </strong>
                  </div>
                  <div class="text-center w-100 mt-2">
                    <router-link
                      data-scroll
                      :to="`/game-topup/${formatText(game.name)}-${game._id}`"
                      class="btn btn-outline-danger w-100 p-1"                      
                      style="font-size: 10px !important;"
                    >
                      {{ getSetting.btn.select.text || "เลือก" }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="gameFilterNormal.length > itemsPerPageMobile">
            <div class="col-12 text-center">
              <button
                @click="loadMoreNormal"
                class="btn btn-danger"
                v-if="displayedGamesNormalMobile.length < gameFilterNormal.length"
              >
              {{ $t('homepage.more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If no games -->
    <div v-if="gameFilterNormal.length == 0">
      <div class="container px-4 mb-4">
        <div class="row justify-content-center">
          <div class="col text-center px-2">
            <div class="card blur-dark-fade p-5">
              <strong>{{ $t('homepage.notFoundGame') }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="this.gamesTopupAuto.length > 0">
    <div class="container mt-3">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="title-highlight">
            <strong class="text-white fs-5">{{ $t('menu.toupAuto') }}</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-2">
      <div
        class="d-flex justify-content-between gap-2 flex-wrap flex-md-nowrap home-tab"
      >
        <div
          class="d-flex justify-content-start gap-3 overflow-scroll text-nowrap flex-nowrap w-100"
        >
          <div class="" v-for="(item, index) in postStatusList" :key="index">
            <button
              class="btn mobile-button"
              style="font-size: 0.875rem;"
              :class="
                postStatus == item.status ? 'btn-danger' : 'btn-outline-danger'
              "
              @click="postStatus = item.status"
            >
              <span
                ><i v-if="item.icon" :class="item.icon"></i>
                {{ $t(`homepage.${item.text}`) }}</span
              >
            </button>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <input
            class="form-control p-2"
            type="text"
            v-model="searchInput"
              :placeholder="$t('homepage.searchGame')"
          />
        </div>
      </div>
    </div>

    <div
      class="container px-4"
      v-if="gamesFilterAuto && gamesFilterAuto.length && !isMobile"
    >
      <div class="row justify-content-start">
        <div class="col-12 mb-3">
          <div class="row">
            <div
              v-for="(game, index) in displayedGames"
              :key="index"
              class="col-lg-2 col-md-3 col-6 px-2"
            >
              <div
                class="card card-game d-flex flex-column position-relative cursor-pointer zoom-product"
                @click="goToBuy(game)"
              >
                <span
                  class="badge badge-success animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status && game.post_status === 1"
                  >new</span
                >
                <span
                  class="badge badge-danger animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status && game.post_status === 2"
                  >hot</span
                >
                <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="game-icon img-fluid w-100 h-100 mb-2" :alt="`รับเติมเกม ${ game.name }`"> -->
                <img
                  v-lazy="game.icon_url"
                  class="game-icon img-fluid w-100 h-100 mb-2"
                  :alt="`รับเติมเกม ${game.name}`"
                />
                <div class="card-body d-flex flex-column flex-grow-1 p-0">
                  <div class="d-flex justify-content-center align-items-center h-100" style="min-height:44px;">
                    <strong class="title text-sm text-white text-center">
                      {{ game.name }}
                    </strong>
                  </div>
                  <div class="text-center w-100 mt-2">
                    <router-link
                      data-scroll
                      :to="`/game-topup/${game._id}`"
                      class="btn btn-sm btn-danger w-100"
                      >{{ $t('homepage.choose') }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" v-if="gamesFilterAuto.length > itemsPerPage">
            <div class="col-12 text-center">
              <button
                @click="loadMoreDesktop"
                class="btn btn-danger"
                v-if="displayedGames.length < gamesFilterAuto.length"
              >
              {{ $t('homepage.more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container px-4"
      v-if="gamesFilterAuto && gamesFilterAuto.length && isMobile"
    >
      <div class="row justify-content-start">
        <div class="col-12 mb-3">
          <div class="row">
            <div
              v-for="(game, index) in displayedGamesMobile"
              :key="index"
              class="col-lg-2 col-md-3 col-4 mb-4"
            >
              <div
                class="card card-game d-flex flex-column position-relative cursor-pointer zoom-product"
                @click="goToBuy(game)"
              >
                <span
                  class="badge badge-success animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status === 1"
                  >new</span
                >
                <span
                  class="badge badge-danger animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0"
                  v-if="game.post_status === 2"
                  >hot</span
                >
                <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="game-icon img-fluid w-100 h-100 mb-2" :alt="`รับเติมเกม ${ game.name }`" style="height: 100px !important;"> -->
                <img
                  v-lazy="game.icon_url"
                  class="game-icon img-fluid w-100 h-100 mb-2"
                  :alt="`รับเติมเกม ${game.name}`"
                  style="height: 100px !important;"
                />
                <div class="card-body d-flex flex-column flex-grow-1 p-0">
                  <div class="d-flex justify-content-center align-items-center h-100" style="min-height:44px;">
                    <strong class="title text-sm text-white text-center">
                      {{ game.name }}
                    </strong>
                  </div>
                  <div class="text-center w-100 mt-2">
                    <router-link
                      data-scroll
                      :to="`/game-topup/${formatText(game.name)}-${game._id}`"
                      class="btn btn-outline-danger w-100 p-1"
                      style="font-size: 10px !important;"
                    >
                      {{ getSetting.btn.select.text || "เลือก" }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="gamesFilterAuto.length > itemsPerPageMobile">
            <div class="col-12 text-center">
              <button
                @click="loadMore"
                class="btn btn-danger"
                v-if="displayedGamesMobile.length < gamesFilterAuto.length"
              >
              {{ $t('homepage.more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If no games -->
    <div v-if="gamesFilterAuto.length == 0">
      <div class="container px-4 mb-4">
        <div class="row justify-content-center">
          <div class="col text-center px-2">
            <div class="card blur-dark-fade p-5">
              <strong>{{ $t('homepage.notFoundGame') }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="j-space my-5"
    v-if="gamesFilterAuto && gamesFilterAuto.length"
  ></div>

  <div
    class="container"
    v-if="
      gamesFilterAuto &&
      gamesFilterAuto.length &&
      getSetting?.page?.home_page.banners3
    "
  >
    <div
      id="carouselExampleIndicators3"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <ol
        class="carousel-indicators"
        v-if="getSetting?.page?.home_page.banners3.length > 1"
      >
        <li
          data-bs-target="#carouselExampleIndicators3"
          v-for="(slide, index) in getSetting?.page?.home_page.banners3"
          :key="index"
          :data-bs-slide-to="index"
          :class="index == 0 ? 'active' : ''"
        ></li>
      </ol>
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(slide, index) in getSetting?.page?.home_page.banners3"
          :key="index"
          :class="index == 0 ? 'active' : ''"
        >
          <a v-if="slide.url" :href="slide.url">
            <img class="d-block w-100" :src="slide.img_url" alt="" />
          </a>
          <img v-else class="d-block w-100" :src="slide.img_url" alt="" />
        </div>
      </div>
      <a
        v-if="getSetting?.page?.home_page.banners3.length > 1"
        class="carousel-control-prev"
        href="#carouselExampleIndicators3"
        role="button"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        v-if="getSetting?.page?.home_page.banners3.length > 1"
        class="carousel-control-next"
        href="#carouselExampleIndicators3"
        role="button"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="container comment-con d-none d-md-block">
    <div class="card bg-white shadow-lg rounded-lg overflow-hidden mb-4">
      <div class="card-header bg-primary text-white py-3">
        <strong class="mb-0 font-weight-bold text-white">Review</strong>
      </div>
      <div class="card-body p-4">
        <div
          class="fb-comments"
          data-href="https://termgame.goldenbug.co/"
          data-width="100%"
          data-numposts="5"
          data-order-by="reverse_time"
        ></div>
      </div>
    </div>
  </div>

  <div class="j-space my-3"></div>
  <ModalDialog
    id="jkanban-info-modal"
    class="modal fade"
    style="display: none"
    tabindex="-1"
    role="dialog"
    :bannerUrl="getWelcomeBanner.banner_url"
    v-if="getWelcomeBanner && getWelcomeBanner.banner_url"
  />

  <div class="container">
    <div class="row d-flex justify-content-center align-items-center my-2">
      <div class="col-3 col-md-1">
        <img
          src="../../../public/logo.png"
          class="img-fluid w-100 h-100 m-auto"
          alt="เติมเกม"
        />
      </div>
    </div>
  </div>
  <div class="container d-flex flex-column justify-content-center align-items-center">
    <h1 class="seo_title" style="font-size: 1.2rem;">{{ $t('seo.title') }}</h1>
    <h2 class="mb-3 text-center fs-6" style="color: white;">
      {{ $t('seo.subtitle') }}
    </h2>
    <p class="description_seo" style="color: white;">
      <span
        class="text-danger fw-bold cursor-pointer"
        @click="$router.push('#')"
      >
        {{ $t('seo.content') }}
      </span>
      {{ $t('seo.details') }}
      <a
        href="https://gold.razer.com/us/en"
        class="text-danger fw-bold"
        target="_blank"
      >
        {{ $t('seo.link') }}
      </a>
      {{ $t('seo.footer') }}
    </p>
  </div>
  <div class="container">
    <h2 class="seo_title text-center fs-5">{{ $t('faq.title') }}</h2>
    <div>
      <!-- Question 1 -->
      <div>
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 p-2"
          style="background-color: rgb(29, 29, 29)"
          @click="toggleP_1"
        >
          <h3 class="text-white mb-0 fw-normal" style="font-size: 14px;">{{ $t('faq.questions.0.question') }}</h3>
          <i
            class="fas text-white me-4"
            :class="showP_1 ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
        <transition name="slide-fade">
          <p
            v-if="showP_1"
            class="text-white text-light ms-3 animated-h2 mt-3"
            style="font-size: 12px;"
            v-html="$t('faq.questions.0.answer')"
          ></p>
        </transition>
      </div>

      <!-- Question 2 -->
      <div>
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 p-2"
          style="background-color: rgb(29, 29, 29)"
          @click="toggleP_2"
        >
          <h3 class="text-white mb-0 fw-normal" style="font-size: 14px;">{{ $t('faq.questions.1.question') }}</h3>
          <i
            class="fas text-white me-4"
            :class="showP_2 ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
        <transition name="slide-fade">
          <p
            v-if="showP_2"
            class="text-white text-light ms-3 animated-h2 mt-3"
            style="font-size: 12px;"
            v-html="$t('faq.questions.1.answer')"
          ></p>
        </transition>
      </div>

      <!-- Question 3 -->
      <div>
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 p-2"
          style="background-color: rgb(29, 29, 29)"
          @click="toggleP_3"
        >
          <h3 class="text-white mb-0 fw-normal" style="font-size: 14px;">{{ $t('faq.questions.2.question') }}</h3>
          <i
            class="fas text-white me-4"
            :class="showP_3 ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
        <transition name="slide-fade">
          <p
            v-if="showP_3"
            class="text-white text-light ms-3 animated-h2 mt-3"
            style="font-size: 12px;"
            v-html="$t('faq.questions.2.answer')"
          ></p>
        </transition>
      </div>

      <!-- Question 4 -->
      <div>
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 p-2"
          style="background-color: rgb(29, 29, 29)"
          @click="toggleP_4"
        >
          <h3 class="text-white mb-0 fw-normal" style="font-size: 14px;">{{ $t('faq.questions.3.question') }}</h3>
          <i
            class="fas text-white me-4"
            :class="showP_4 ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
        <transition name="slide-fade">
          <p
            v-if="showP_4"
            class="text-white text-light ms-3 animated-h2 mt-3"
            style="font-size: 12px;"
            v-html="$t('faq.questions.3.answer')"
          ></p>
        </transition>
      </div>

      <!-- Question 5 -->
      <div>
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 p-2"
          style="background-color: rgb(29, 29, 29)"
          @click="toggleP_5"
        >
          <h3 class="text-white mb-0 fw-normal" style="font-size: 14px;">{{ $t('faq.questions.4.question') }}</h3>
          <i
            class="fas text-white me-4"
            :class="showP_5 ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
        </div>
        <transition name="slide-fade">
          <p
            v-if="showP_5"
            class="text-white text-light ms-3 animated-h2 mt-3"
            style="font-size: 12px;"
            v-html="$t('faq.questions.4.answer')"
          ></p>
        </transition>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MainSliderHome from "@/views/pages/components/MainSliderHome.vue";

import AOS from "aos";
import "aos/dist/aos.css";
import Rellax from "rellax";
import { mapMutations, mapActions, mapGetters } from "vuex";
import ModalDialog from "../../components/Modal.vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    MainSliderHome,
    ModalDialog,
    Carousel,
    Slide,
    Navigation,
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
  },
  data() {
    return {
      searchInput: "",
      searchInputNormal: "",
      postStatus: "",
      postStatusNormal: "",
      postStatusList: [
        {
          status: "",
          text: "all",
          icon: "fas fa-th",
        },
        {
          status: 1,
          text: "new",
          icon: "fas fa-meteor",
        },
        {
          status: 2,
          text: "hot",
          icon: "fas fa-fire",
        },
      ],
      verify: window.localStorage.getItem("verify") || false,
      currentSlide: 0,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: true,
      },
      breakpoints: {
        400: {
          itemsToShow: 1.2,
          snapAlign: "center",
        },
        500: {
          itemsToShow: 1.5,
          snapAlign: "center",
        },
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        900: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        1000: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      showP_1: false,
      showP_2: false,
      showP_3: false,
      showP_4: false,
      showP_5: false,
      isMobile: window.innerWidth <= 768, // Check if the device is mobile
      displayedItems: 18,
      itemsPerPage:18,
      itemsPerPageMobile: 12,
      displayedItemsMobile: 12,
      language: localStorage.getItem("language") || 'th',
      country: null,
    };
  },
  async created() {
    this.getCountryFromIP();  // เรียกฟังก์ชันเมื่อ component ถูก mount
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  async mounted() {
    AOS.init({ disable: "mobile" });
    if (document.getElementsByClassName("rellax")) {
      new Rellax(".rellax", {
        speed: 1,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true,
      });

      //   rellax.destroy();
    }

    if (!this.checkLogin) {
      this.$store.dispatch("logOut");
    }

    if (this.getWelcomeBanner.banner_url) {
      const now = new Date().getTime();
      const oneHourInMs = 60 * 60 * 1000;
      // const oneHourInMs =  1000;
      const lastModalClosedTime = localStorage.getItem("modalData");
      if (!localStorage.getItem("modalData")) {
        this.showModal();
      } else if (
        lastModalClosedTime &&
        now - parseInt(lastModalClosedTime) >= oneHourInMs
      ) {
        this.showModal();
      }
    }

    this.$nextTick(() => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    });
    await window.addEventListener("resize", this.handleResize);
  },
  async beforeUnmount() {
    await window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters([
      "games",
      "gamesTopupAuto",
      "gamesTopupNormal",
      "homeBanner",
      "getSetting",
      "isLoggedIn",
    ]),
    cardFilterNormal() {
      let filteredCards = this.gamesTopupAuto.filter(
        (game) => game.product_type == 2
      );
      // console.log('filteredCards: ', filteredCards);
      return filteredCards;
    },
    gameFilterNormal() {
      let filteredGames = this.gamesTopupNormal.filter(
        (game) => game.product_type == 1
      );
      if (this.searchInputNormal) {
        if (this.postStatusNormal) {
          return filteredGames.filter((game) => {
            return (
              game.name
                .toLowerCase()
                .includes(this.searchInputNormal.toLowerCase()) &&
              game.post_status == this.postStatusNormal
            );
          });
        }
        return filteredGames.filter((game) => {
          return game.name
            .toLowerCase()
            .includes(this.searchInputNormal.toLowerCase());
        });
      }
      if (this.postStatusNormal) {
        return filteredGames.filter((game) => {
          return game.post_status == this.postStatusNormal;
        });
      }
      return filteredGames;
    },
    gamesFilterAuto() {
      let filteredGames = this.gamesTopupAuto.filter(
        (game) => game.product_type == 1
      );
      if (this.searchInput) {
        if (this.postStatus) {
          return filteredGames.filter((game) => {
            return (
              game.name
                .toLowerCase()
                .includes(this.searchInput.toLowerCase()) &&
              game.post_status == this.postStatus
            );
          });
        }
        return filteredGames.filter((game) => {
          return game.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase());
        });
      }
      if (this.postStatus) {
        return filteredGames.filter((game) => {
          return game.post_status == this.postStatus;
        });
      }
      return filteredGames;
    },
    getWelcomeBanner() {
      // console.log('this.getSetting?: ', this.getSetting);
      return this.getSetting?.page?.home_page.welcome_banner;
    },
    displayedGames() {
      return this.gamesFilterAuto.slice(0, this.displayedItems);
    },
    displayedGamesNormal() {
      return this.gameFilterNormal.slice(0, this.displayedItems);
    },
    displayedGamesMobile() {
      return this.gamesFilterAuto.slice(0, this.displayedItemsMobile);
    },
    displayedGamesNormalMobile() {
      return this.gameFilterNormal.slice(0, this.displayedItemsMobile);
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["fetchGames", "fetchBanners"]),
    getGameById(game_id) {
      return this.games.find((x) => x._id == game_id).icon_url;
    },
    formatText(gameName) {
      return gameName.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();  ;
    },
    goToBuy(param) {
      const sanitizedGameName = param.name.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();   
      const formatText = `${sanitizedGameName}-${param._id}`;
      
      return this.$router.push({
        name: "GameTopUpSingle",
        params: { game_id: formatText },
      });
    },
    checkLogin() {
      return typeof this.getMemberProfile !== "undefined";
    },
    async showModal() {
      const bootstrap = this.$store.state.defaultStore.bootstrap;

      let jkanbanInfoModal = document.getElementById("jkanban-info-modal");

      var myModal = new bootstrap.Modal(jkanbanInfoModal, {
        show: true,
      });
      myModal.show();
    },

    hideModal() {
      document.querySelector("#jkanban-info-modal").classList.remove("show");
      document.querySelector("body").classList.remove("modal-open");
      document.querySelector("body").style = "";
      document.querySelector(".modal-backdrop").remove();
    },
    toggleP_1() {
      this.showP_1 = !this.showP_1;
    },
    toggleP_2() {
      this.showP_2 = !this.showP_2;
    },
    toggleP_3() {
      this.showP_3 = !this.showP_3;
    },
    toggleP_4() {
      this.showP_4 = !this.showP_4;
    },
    toggleP_5() {
      this.showP_5 = !this.showP_5;
    },
    async handleResize() {
      this.isMobile = await window.innerWidth <= 768;
    },
    loadMoreDesktop() {
      this.loadMoreItems('gamesFilterAuto', 'displayedItems', this.itemsPerPage);
    },
    loadMoreNormalDesktop() {
      this.loadMoreItems('gameFilterNormal', 'displayedItems', this.itemsPerPage);
    },
    loadMore() {
      this.loadMoreItems('gamesFilterAuto', 'displayedItemsMobile', this.itemsPerPageMobile);
    },
    loadMoreNormal() {
      this.loadMoreItems('gameFilterNormal', 'displayedItemsMobile', this.itemsPerPageMobile);
    },
    loadMoreItems(filterKey, displayedKey, itemsPerPage) {
      this[displayedKey] += itemsPerPage;
      const totalItems = this[filterKey].length;
      if (this[displayedKey] > totalItems) {
        this[displayedKey] = totalItems;
      }
    },
     getCountryFromIP() {
      if(this.language){
        if(this.language == 'ph'){
          this.country = 'php'
        } else if (this.language == 'th'){
          this.country = null
        }
        this.fetchGames(this.country);
      }else{
        this.fetchGames(null);
      }
    }
  },
};
</script>

<style lang="scss">
.home-tab {
  .btn-outline-danger {
    background-color: #2c2c2c;
    border: none;
    &:hover {
      background-color: #2c2c2c !important;
      transform: unset !important;
      opacity: unset !important;
    }
  }
  padding: 0px 24px;
}
.game-icon {
  width: 100%;
}
.modal-dialog {
  max-width: 750px !important;
  padding: 0 1rem;
}
.seo_title {
  text-align: center;
}
.zoom-product {
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease;
}

.zoom-product:before,
.zoom-product:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -100%;
  width: 60%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(25deg);
  z-index: 1;
}

.zoom-product:before {
  transition: all 1.2s ease;
}

.zoom-product:after {
  transition: all 1.2s ease;
  transition-delay: 0.4s;
}

.zoom-product:hover:before,
.zoom-product:hover:after {
  left: 130%;
}

.zoom-product:hover {
  transform: scale(1.03);
}

.zoom-product .btn {
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
  margin-bottom: 0;
}

.zoom-product:hover .btn {
  background-color: #dc3545;
  color: white;
}

.card-box {
  transition: all 0.3s ease;
  overflow: hidden;
  border: none;
  background-color: #2c2c2c !important;
  cursor: pointer;
}

.card-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-gradient {
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  padding: 1rem;
  border-radius: 10px;
}

.content-con {
  background-color: red;
  height: 100%;
  text-align: start;
}
.card-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #fff;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 15px;
}

.card-text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 20px;
  max-height: 70px;
}

.card-box img {
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.card-box:hover img {
  transform: scale(1.05);
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carousel__prev,
.carousel__next {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel__prev:hover,
.carousel__next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel__prev {
  left: -24px;
}

.carousel__next {
  right: -24px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
.animated-h2 {
  margin-top: 20px;
}
.card-header {
  background: linear-gradient(60deg, #d40778, #610248);
}

.card-header h4 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.fb-comments,
.fb-comments iframe {
  width: 100% !important;
}

.fb-comments {
  border-radius: 0.5rem;
  overflow: hidden;
}
.title-highlight {
  position: relative;
  padding-left: 15px;
  margin-bottom: 1rem;
}

.title-highlight::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #e00b60 !important;
}
.title{
  text-align: center;
  overflow: visible;
}
.zoom-product {
  strong {
      font-size: 1rem !important;
      font-weight: 500;
      letter-spacing: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 10px;
  }
}
.btn-danger{
  font-size: 0.875rem !important;
}
.btn{
  margin-bottom: 0 !important;
}
.description_seo{
  font-size: 0.875rem;
}
.cash-card-con{
  height: 170px; 
  padding: 1rem;
}

/* ปรับตำแหน่งปุ่มสำหรับหน้าจอขนาดเล็ก */
@media (max-width: 768px) {
  .carousel__prev {
    left: 0;
  }
  .carousel__next {
    right: 0;
  }
  .mobile-button {
    padding: 10px 15px !important;
    span {
      font-size: 10px !important;
    }
  }
  .seo_title{
    font-size: 16px !important;
  }
  h2.fs-6{
    font-size: 12px !important;
  }
  strong{
    font-size: 14px !important;
  }
  strong.fs-5{
    font-size: 14px !important;
  }
  .btn-danger{
    font-size: 10px !important;
  }
  .form-control{
    font-size: 10px !important;
  }
  .badge{
    font-size: 10px !important;
  }
  .btn{
    padding: 0.5rem 1rem  !important;
  }
  .j-space.my-5{
    margin: 0 !important;
  }
  .col-4.mb-4{
    margin-bottom: 1rem !important;
  }
  .card.bg-white.shadow-lg.rounded-lg.overflow-hidden.mb-4 {
    max-height: 500px !important;
    overflow-y: auto !important;
  }
  .description_seo{
    font-size: 10px !important;
  }
  .seo_title.fs-5{
    font-size: 14px !important;
  }
  h3,i{
    font-size: 12px !important;
  }
  .cash-card-con{
    height: 140px  !important;
  }
}
</style>
