<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div class="container mt-6" :style="getHomePageBgColor">
    <div class="row">
      <div class="col-lg-7 text-center mx-auto mt-7">
        <h1 class="text-white fs-3">{{ $t('sideNav.historyOrder') }}</h1>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <!-- <nav-pill /> -->
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 pt-4">
        <orders />
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SideNav from "@/views/pages/Account/components/SideNav.vue";
import Orders from "@/views/pages/Orders.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "MemberProfile",
  components: {
    Navbar,
    SideNav,
    Orders,
    AppFooter
  },
  created() {
    this.checkLogin();
  },
  computed: {
    ...mapGetters(['getSetting', 'getMemberProfile']),
    getHomePageBgColor() {
      return this.getSetting && this.getSetting.page && this.getSetting.page.home_page && this.getSetting.page.home_page.bgcolor
        ? `--jcr-main-bgcolor: ${this.getSetting.page.home_page.bgcolor};`
        : '';
    }
  },
  methods: {
    async checkLogin() {
      const checkLogin = typeof this.getMemberProfile !== 'undefined';
      if (!checkLogin) {
        // console.log(checkLogin)
        await this.$store.dispatch('logOut');
        return this.$router.push('/signin');
      }
    },
  }
};
</script>
