import axios from 'axios';

// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://uatapi.goldenbug.co/api";

const LINE_API = 'https://access.line.me/oauth2/v2.1';

export default {
  state: {
    status: '',
    token: window.localStorage.getItem('token') || '',
    user: {}
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, { token, user }) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user = {}
    },
    setProfile(state, user) {
      state.user = user
    }
  },
  actions: {
    async signup({ commit }, user) {
      commit('auth_request');
      try {
        const res = await axios.post(`${API_URL}/signup`, user)
        if (res.data.success) {
          const token = res.data.token;
          const user = res.data.user;
          window.localStorage.setItem('token', token);
          axios.defaults.headers.common['Authorization'] = token;
          commit('auth_success', { token, user });
        }
        return res;
      } catch (err) {
        commit('auth_error', err);
        window.localStorage.removeItem('token');
        throw err;
      }
    },
    async signin({ commit }, user) {
      commit('auth_request');
      try {
        const res = await axios.post(`${API_URL}/signin`, user);
        if (res.data.success) {
          const token = res.data.token;
          const user = res.data.user;
          window.localStorage.setItem('token', token);
          axios.defaults.headers.common['Authorization'] = token;
          commit('auth_success', { token, user });
        }
        return res;
      } catch (err) {
        commit('auth_error', err);
        window.localStorage.removeItem('token');
        throw err;
      }
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        window.localStorage.removeItem('jcr_token');
        window.localStorage.removeItem('jcr_pwd');
        window.localStorage.removeItem('jcr_user');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    // async getProfile({ commit }) {
    //   commit('auth_request');
    //   try {
    //     const res = await axios.get(`${API_URL}/profile`);
    //     commit('setProfile', res.data.user);
    //     return res;
    //   } catch (err) {
    //     commit('auth_error', err);
    //     throw err;
    //   }
    // },
    async changePassword({ commit }, passwords) {
      commit('auth_request');
      try {
        const res = await axios.put(`${API_URL}/change-password`, passwords);
        return res;
      } catch (err) {
        commit('auth_error', err);
        throw err;
      }
    },
    lineLogin() {
      const clientId = '<Your Line Login Channel ID>';
      const redirectUri = '<Your Redirect URI>';
      const state = '<A random state string>';
      const scope = 'profile';
      window.location.href = `${LINE_API}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    },
    async handleLineLoginRedirect({ commit }, code) {
      commit('auth_request');
      try {
        const clientId = '<Your Line Login Channel ID>';
        const clientSecret = '<Your Line Login Channel Secret>';
        const redirectUri = '<Your Redirect URI>';
        const res = await axios.post(`${LINE_API}/token`, {
          grant_type: 'authorization_code',
          code,
          redirect_uri: redirectUri,
          client_id: clientId,
          client_secret: clientSecret,
        });

        const token = res.data.access_token;
        const user = await axios.get(`${API_URL}/profile`, { headers: { Authorization: `Bearer ${token}` } });

        window.localStorage.setItem('token', token);
        commit('auth_success', { token, user });
        return user;
      } catch (err) {
        commit('auth_error', err);
        window.localStorage.removeItem('token');
        throw err;
      }
    },
  },
  getters: {
    authStatus: state => state.status,
    userProfile: state => state.user,
  }
}