import { createApp } from "vue";
import { createI18n } from 'vue-i18n'; // นำเข้า vue-i18n
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import VueLazyload from 'vue-lazyload'
import 'animate.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { initFbPixel, initTiktokAnalytics, initGoogleTag, initGoogleTagNew, initHotjar } from './pixelConfig'

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCGHqXixde8_DQpwASSCP5hAb74Es6F9Kw",
    authDomain: "termgame-4a52a.firebaseapp.com",
    projectId: "termgame-4a52a",
    storageBucket: "termgame-4a52a.appspot.com",
    messagingSenderId: "1055649956934",
    appId: "1:1055649956934:web:461e184934144d17617647",
    measurementId: "G-LCRFKR7Y4K"
};

const firebaseapp = initializeApp(firebaseConfig);
getAnalytics(firebaseapp);

// เรียกใช้ Pixel
initFbPixel();
initTiktokAnalytics();
initGoogleTag();
initGoogleTagNew();
initHotjar();

// ข้อความแปล
import ph from './locales/ph'
import en from './locales/en';
import th from './locales/th';

const i18n = createI18n({
    locale: 'th', // ภาษาเริ่มต้น
    fallbackLocale: 'en', // ภาษา fallback
    messages: {
        ph,
        en,
        th
    }
});

const appInstance = createApp(App);

appInstance.config.globalProperties.$filters = {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        let val = (value / 1).toFixed(2);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

appInstance.use(i18n); // ใช้ vue-i18n
appInstance.use(VueLazyload);
appInstance.use(VueLazyload, {
    preLoad: 0.1,
    attempt: 1
});
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
