<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div class="mt-7">
    <div class="row">
      <div class="my-5">
        <div class="title-head text-center" v-if="gameDetail">
          <h1 class="text-white font-mobile fs-2">{{ $t('checkOrder.detailHeader')}}</h1>
          <h2 class="text-white font-mobile">
            {{ this.gameDetail.name }}
          </h2>
        </div>
        <div class="card shadow-sm rounded-0">
          <div class="card-body">
            <div class="con-img">
              <img
                src="../../assets/img/order/icon-สลิปเติมเกม.png"
                alt="process-termcredit"
                class="img-fluid w-40"
                :class="this.showButtonStatus ? 'd-none' : ''"
              />
              <img
                src="../../assets/img/order/icon-สลิปเติมเกม-2.png"
                alt="process-termcredit"
                class="img-fluid w-40"
                :class="this.showButtonStatus ? '' : 'd-none'"
              />
            </div>
            <div class="con-information" v-if="!closeDisplay">
              <div class="row mb-4 w-90 m-auto mobile-display">
                <div class="col-md-8" v-if="getMemberProfile">
                  <h3 class="h5 text-danger">{{ $t('checkOrder.informationUserText')}}</h3>
                  <p class="fw-bold">{{ getMemberProfile.full_name }}</p>
                  <p class="fw-bold">{{ getMemberProfile.email }}</p>
                  <p class="fw-bold">{{ getMemberProfile.mobile }}</p>
                </div>
                <div class="col-md-4" v-if="this.orderData && this.gameDetail">
                  <h3 class="h5 text-danger">{{ $t('checkOrder.informationGameText')}}</h3>
                  <p class="fw-bold">{{ $t('checkOrder.nameGame')}}: {{ this.gameDetail.name }}</p>
                  <p class="fw-bold">{{ $t('checkOrder.idGame')}}: {{ this.orderData.uid }}</p>
                  <p v-if="this.orderData.server_name" class="fw-bold">
                    {{ $t('checkOrder.serverGame')}}: {{ this.orderData.server_name }}
                  </p>
                </div>
              </div>
              <div class="table-responsive con-table">
                <table class="table" v-if="product">
                  <thead>
                    <tr>
                      <th class="w-50 fw-bolder">{{ $t('checkOrder.list')}}</th>
                      <th class="text-center w-25 fw-bolder">{{ $t('checkOrder.amount')}}</th>
                      <th class="text-center w-25 fw-bolder">{{ $t('checkOrder.price')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in this.orderData.product_buy" :key="index">
                      <td class="w-50 truncate">
                        <div class="item-name" :title="item.product_name">{{ item.product_name }}</div>
                      </td>
                      <td class="text-center w-25">
                        {{ item.product_total }}
                      </td>
                      <!-- <td v-if="myWallet < totalPrice" class="text-center w-25">
                        {{ getFormattedPriceAmount(item.product_price) }} {{ $t('checkOrder.bath')}}
                      </td> -->
                      <td
                        class="text-center w-25"
                      >
                        {{ this.$filters.toCurrency(+(item.product_price).toFixed(2)) }} {{ $t('checkOrder.bath')}}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="border-line" v-if="this.myWallet < this.totalPrice">
                      <td colspan="2" class="text-end">
                        <strong>{{ $t('historyOrder.total')}}:</strong>
                      </td>
                      <td class="text-center">
                        <strong>{{ this.$filters.toCurrency(+totalPrice) }} {{ $t('checkOrder.bath')}}</strong>
                      </td>
                    </tr>
                    <tr v-if="this.myWallet < this.totalPrice">
                      <td colspan="2" class="text-end">
                        <strong class="text-danger">{{ $t('checkOrder.creditHold')}}:</strong>
                      </td>
                      <td class="text-center">
                        <strong class="text-danger">{{ this.$filters.toCurrency(+this.myWallet.toFixed(2)) }} {{ $t('checkOrder.bath')}}</strong>
                      </td>
                    </tr>
                    <tr class="border-line">
                      <td colspan="2" class="text-end">
                        <strong>{{ $t('checkOrder.total')}}:</strong>
                      </td>
                      <td class="text-center">
                        <strong v-if="this.myWallet < this.totalPrice">{{ this.$filters.toCurrency(+(totalPrice-this.myWallet).toFixed(2)) }} {{ $t('checkOrder.bath')}}</strong>
                        <strong v-if="this.myWallet >= this.totalPrice">{{ this.$filters.toCurrency(+totalPrice) }} {{ $t('checkOrder.bath')}}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="row mb-4 w-90 m-auto">
                <div class="col-md-12">
                  <h3 class="text-danger font-remark mt-4">
                    {{ $t('checkOrder.awaitText')}}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
              class="mt-4 d-flex flex-column justify-content-center align-items-center"
              v-if="this.orderData && this.myWallet < this.totalPrice"
            >
              <h3 class="fs-3">หลักฐานการชำระเงิน</h3>
              <div class="con-slip">
                <img
                :src="this.orderData.slip_img_url"
                  alt="สลิปการชำระเงิน"
                  class="img-fluid mt-2"
                  style="max-width: 300px"
                />
              </div>
            </div> -->
          <div class="d-flex justify-content-center text-center">
            <h3 class="text-danger fs-5 mt-4">
              {{ $t('checkOrder.thankyouText')}}
            </h3>
          </div>
          <div class="d-flex justify-content-between mt-4 m-auto w-40">
            <button class="btn" @click="goToHome"> {{ $t('checkOrder.undoWeb')}}</button>
            <div>
              <button
                class="btn btn-primary me-2 btn-status"
                :class="showButtonAgain ? '' : 'd-none'"
                @click="sendAgain"
              >
              {{ $t('checkOrder.sendAgain')}}
              </button>
              <button
                v-if="showButtonStatus"
                class="btn btn-primary btn-status"
                @click="checkStatus"
              >
              {{ $t('checkOrder.recheck')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="j-space my-3"></div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Navbar,
    AppFooter,
  },
  data() {
    return {
      orderData: null,
      totalPrice: null,
      intervalId: null,
      productId: null,
      gameDetail: null,
      showButtonAgain: false,
      showButtonStatus: false,
      maxRefreshAttempts: 5, // กำหนดจำนวนครั้งสูงสุดที่อนุญาตให้รีเฟรช
      isRefreshing: false, // เพิ่มตัวแปรเพื่อติดตามสถานะการรีเฟรช
      closeDisplay: false,
      isOnThisPage: true,
    };
  },
  computed: {
    ...mapGetters(["myWallet", "orders", "getMemberProfile", "product"]),
    totalAmount() {
      return this.orderData ? this.orderData.itemPrice : 0;
    },
  },
  watch: {
    myWallet(newValue, oldValue) {
      console.log("myWallet changed:", oldValue, "->", newValue);
      // console.log('productList', this.product);
      // console.log('getMemberProfile: ', this.getMemberProfile);
      // console.log('this.totalPrice: ', this.totalPrice);
      // console.log("DataServer", this.orderData);
      // คุณสามารถทำอย่างอื่นเพิ่มเติมตรงนี้เมื่อ myWallet เปลี่ยนแปลง
    },
  },
  created() {
    this.getProfile();
    this.getMyCredit();
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.loadOrderData();
    if (this.orderData && this.totalPrice) {
      this.createOrder();
    }
    // โหลดค่า refreshCount จาก localStorage ถ้ามี
    const savedRefreshCount = localStorage.getItem("refreshCount");
    this.refreshCount = savedRefreshCount ? parseInt(savedRefreshCount, 10) : 0;
    this.isOnThisPage = true;
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.$store.dispatch("InitialCart");
    this.resetRefreshCount();
    this.clearOrderData();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isOnThisPage) {
      this.saveOrderData();
      if (this.orderData && this.totalPrice) {
        console.log("สร้างคำสั่งซื่อใหม่");
      }
    } else {
      localStorage.removeItem("incompleteOrderData");
    }
    this.isOnThisPage = false; // เปลี่ยนเป็น false เมื่อออกจากหน้า
    next();
  },
  async mounted() {
    this.orderData.product_buy.forEach((element) => {
      this.productId = element.product_id;
    });
    await this.fetchProductsById(this.productId);

    this.filterGameList();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions([
      "fetchProductsById",
      "getProfile",
      "getMyCredit",
      "saveIncompleteOrder",
    ]),
    loadOrderData() {
      this.orderData = JSON.parse(localStorage.getItem("orderData"));
      this.totalPrice = JSON.parse(localStorage.getItem("totalPrice"));
    },
    saveOrderData() {
      localStorage.setItem("orderData", JSON.stringify(this.orderData));
      localStorage.setItem("totalPrice", JSON.stringify(this.totalPrice));
      let PreformData = { ...this.orderData, totalPrice: this.totalPrice };
      if (this.isOnThisPage) {
        this.saveIncompleteOrder(PreformData);
      }
    },
    clearOrderData() {
      localStorage.removeItem("orderData");
      localStorage.removeItem("totalPrice");
      this.orderData = null;
      this.totalPrice = null;
    },
    showError(message) {
      this.$swal({
        icon: "error",
        title: this.$t('errorTitle'),
        text: message,
      });
    },
    showSuccess(message) {
      this.$swal({
        icon: "success",
        title: this.$t('checkOrder.topupSuccess'),
        text: message,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push("/member/order");
        }
      });
    },
    sendAgain() {
      console.log("sendAgain called");
      this.updateWallet()
        .then(() => {
          this.$nextTick(() => {
            if (this.myWallet >= this.totalPrice) {
              this.performCreateOrder();
            } else {
              this.$swal({
                icon: "error",
                title: this.$t('checkOrder.topupError'),
                text: this.$t('checkOrder.contactAdmin'),
                showCancelButton: true,
                confirmButtonText: this.$t('contactAdmin'),
                cancelButtonText: this.$t('cancle'),
              }).then((result) => {
                if (result.isConfirmed) {
                  window.open("https://lin.ee/dvfKmBo", "_blank");
                } else {
                  this.isOnThisPage = false;
                  localStorage.removeItem("incompleteOrderData");
                }
              });
              this.showButtonAgain = false;
              this.clearOrderData();
              // this.resetRefreshCount(); // รีเซ็ตจำนวนครั้งที่รีเฟรชเมื่อสร้างคำสั่งซื้อสำเร็จ
              this.closeDisplay = true;
              this.isOnThisPage = false;
              localStorage.removeItem("incompleteOrderData");
            }
          });
        })
        .catch((error) => {
          console.error("Failed to update wallet:", error);
          this.showError("ไม่สามารถอัพเดตข้อมูล wallet ได้");
        });
    },
    async createOrder() {
      if (this.isOnThisPage) {
        try {
          const startTime = Date.now();
          const timeout = 1 * 60 * 1000; // ตั้งเวลาสูงสุด 30 นาที
          let initialWallet = this.myWallet;
          let hasRefreshed = false;

          while (this.myWallet < this.totalPrice) {
            if (Date.now() - startTime > timeout) {
              console.error(
                "Exceeded maximum wait time for wallet balance to update."
              );
              this.$swal({
                icon: "error",
                title: this.$t('checkOrder.timeout'),
                text: this.$t('checkOrder.contactAdmin'),
                showCancelButton: true,
                confirmButtonText: this.$t('contactAdmin'),
                cancelButtonText: this.$t('cancle'),
              }).then((result) => {
                if (result.isConfirmed) {
                  window.open("https://lin.ee/dvfKmBo", "_blank");
                } else {
                  this.isOnThisPage = false;
                  localStorage.removeItem("incompleteOrderData");
                }
              });
              this.showButtonAgain = true;
              this.isOnThisPage = false;
              localStorage.removeItem("incompleteOrderData");
              return;
            }

            console.log("เงินไม่พอ รออัพเดต 10 วินาที");
            await new Promise((resolve) => setTimeout(resolve, 10000));
            await this.updateWallet();

            // ตรวจสอบว่า myWallet เพียงพอหรือไม่ก่อน
            if (this.myWallet >= this.totalPrice) {
              console.log("เงินเพียงพอแล้ว กำลังสร้างคำสั่งซื้อ");
              await this.performCreateOrder();
              return; // ออกจากฟังก์ชันหลังจากสร้างคำสั่งซื้อ
            }

            // ถ้าเงินยังไม่พอ แต่ myWallet มีการเปลี่ยนแปลง และยังไม่ได้รีเฟรช
            if (this.myWallet !== initialWallet && !hasRefreshed) {
              console.log(
                "myWallet มีการเปลี่ยนแปลง แต่ยังไม่พอ ทำการรีเฟรชหน้า"
              );
              hasRefreshed = true;
              window.location.reload();
              return; // ออกจากฟังก์ชันเพื่อให้หน้าใหม่โหลดและเริ่มกระบวนการใหม่
            }
          }
          if (this.myWallet >= this.totalPrice)
          setTimeout(() => {
            console.log("เงินเพียงพอแล้ว กำลังสร้างคำสั่งซื้อ");
            this.performCreateOrder();
            this.isOnThisPage = false;
            return
          }, 10000);
        } catch (error) {
          this.showError(error.message || "ไม่สามารถทำรายการได้ในขณะนี้");
          this.showButtonAgain = true;
        }
      } else {
        console.log("ไม่อยู่ในหน้านี้แล้ว ไม่สร้างคำสั่งซื้อ");
      }
    },
    async performCreateOrder() {
      if (this.isOnThisPage) {
        try {
          const response = await this.$store.dispatch(
            "createOrder",
            this.orderData
          );
          console.log("สร้างคำสั่งซื้อสำเร็จ");
          this.showSuccess(this.$t('checkOrder.checkOrderMessage'));           
          this.clearOrderData();
          this.updateProgressStatus();
          this.showButtonAgain = false;
          this.showButtonStatus = true;
          this.closeDisplay = true;
          localStorage.removeItem("incompleteOrderData");
        } catch (error) {
          console.error("เกิดข้อผิดพลาดในการสร้างคำสั่งซื้อ:", error);
          this.showError(error.message || "ไม่สามารถทำรายการได้ในขณะนี้");
          this.showButtonAgain = true;
        }
      } else {
        console.log("ไม่อยู่ในหน้านี้แล้ว ไม่สร้างคำสั่งซื้อ");
      }
    },
    async updateWallet() {
      try {
        await this.$store.dispatch("fetchMyCredit");
        console.log("อัปเดต wallet สำเร็จ ยอดปัจจุบัน:", this.myWallet);
      } catch (error) {
        console.error("ไม่สามารถอัปเดต wallet ได้:", error);
        throw new Error("ไม่สามารถอัพเดตข้อมูล wallet ได้");
      }
    },
    resetRefreshCount() {
      this.refreshCount = 0; // รีเซ็ตจำนวนครั้งที่รีเฟรช
      localStorage.removeItem("refreshCount"); // ลบค่า refreshCount จาก localStorage
    },
    updateProgressStatus() {
      this.currentStep = Math.min(this.currentStep + 1);
    },
    goToHome() {
      this.$router.push("/");
    },
    checkStatus() {
      this.updateProgressStatus();
      this.$router.push("/member/order");
    },
    filterGameList() {
      let resData;
      this.product.map((row) => {
        resData = row.game_id;
      });
      this.gameDetail = resData;
    },
    getFormattedTotalAmount() {
    return (this.totalPrice - this.myWallet).toFixed;
    },
    getFormattedPriceAmount(item) {
    return (item - this.myWallet).toFixed(2);
  }
  },
};
</script>

<style scoped>
* {
  color: rgb(207, 207, 207);
}
.card {
  border-radius: 15px;
  background-color: #000;
}

.table th,
.table td {
  vertical-align: middle;
  border: none;
  padding: 10px 5px;
  white-space: normal;
}
.item-name {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  max-width: 500px;
}

.border-line {
  border-top: 1px solid #c7c7c7b1 !important;
}

.btn {
  border-radius: 25px;
  padding: 15px 20px;
  font-size: 1rem;
  background-color: rgb(29, 29, 29) !important;
  color: rgb(207, 207, 207);
}

.font-remark {
  font-size: 16px;
}

.title-head {
  padding: 5px 0;
}
.con-img {
  display: flex;
  justify-content: center;
}
.con-img-mobile {
  display: flex;
  justify-content: start;
}
.con-information {
  background-color: #fdd3df65;
  padding: 30px 0;
  border-radius: 30px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  width: 60%;
  margin: auto;
  background-color: rgb(29, 29, 29) !important;
}
.con-table {
  background-color: rgba(63, 63, 63, 0.9);
  padding: 0 30px;
}
.con-slip {
  background-color: rgba(235, 235, 235, 0.849);
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ปรับขนาดตัวอักษรสำหรับ mobile */
@media (max-width: 767.98px) {
  .table-responsive {
    overflow-x: auto;
  }

  .table {
    width: 100%;
    table-layout: fixed;
  }

  .table th,
  .table td {
    font-size: 14px;
    padding: 8px 4px;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .table th:first-child,
  .table td:first-child {
    width: 40%;
  }

  .table th:not(:first-child),
  .table td:not(:first-child) {
    width: 30%;
  }
  .truncate {
    max-width: 200px; /* หรือความกว้างที่คุณต้องการ */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .truncate {
    max-width: 100%; /* ใช้ความกว้างเต็มสำหรับมือถือ */
  }

  .item-name {
    font-size: 14px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal; /* อนุญาตให้ขึ้นบรรทัดใหม่บนมือถือ */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* แสดงสูงสุด 2 บรรทัด */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* ปรับขนาดและสไตล์อื่นๆ สำหรับ mobile */
  .con-information {
    width: 95%;
    padding: 15px 0;
  }

  .con-table {
    padding: 0 10px;
  }

  .font-remark {
    font-size: 12px;
  }

  .btn {
    padding: 8px 12px;
    font-size: 14px;
  }

  .w-40 {
    width: 80% !important;
  }
  .fs-5{
    font-size: 12px !important;
    margin: 0 0.875rem;
  }
  .btn{
    font-size: 12px !important;
  }
  h1{
    font-size: 18px !important;
  }
  table{
    *{
      font-size: 12px !important;
    }
  }
  .col-md-8,.col-md-4{
    *{
      font-size: 10px !important;
    }
  }
}
</style>
