<template>
  <nav
    class="navbar navbar-expand-xl top-0 border-bottom z-index-3 shadow start-0 end-0 fixed-top"
    :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
  >
    <div class="container px-0 mt-4">
      <div class="nav-item dropdown language-switcher ms-auto d-xl-none d-block position-absolute top-0 end-0 w-100 py-1" style="padding-right: 5px;">
        <div class="d-flex justify-content-between mx-3 align-items-center">
          <i class="fab fa-facebook fs-4 text-white mb-0"></i>
          <div>
            <button
              class="btn dropdown-toggle m-0 p-0"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ currentLang === 'th' ? 'ไทย/THB' : currentLang === 'en' ? 'US/USD' : currentLang === 'ph' ? 'PH/PHP' : '' }} <i class="fas fa-globe"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="languageDropdown">
              <li>
                <a class="dropdown-item" href="#" @click.prevent="changeLanguage('th')">ไทย / THB</a>
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.prevent="changeLanguage('en')">US / USD</a>
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.prevent="changeLanguage('ph')">PH / PHP</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between w-100 w-xl-auto">
        <router-link
          class="navbar-brand font-weight-bolder ms-xl-0 mr-2"
          :class="darkMode ? 'text-black' : 'text-white'"
          to="/"
          >
          <img src="@/assets/img/logo.png" alt="" height="60" class="d-inline-block w-auto">
        </router-link>
        <ul v-if="getMemberProfile" class="ml-auto d-flex d-xl-none align-items-center list-unstyled m-0">
          <li class="mx-0 nav-item d-flex gap-2 align-items-center">
            <router-link to="/coin-topup" class="cursor-pointer">
              <button class="border-0 coin-box"><i class="fa fa-plus me-1"></i>{{ $t('navbar.topup')}}</button>
            </router-link>
            <router-link to="/member/order" class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center w-100 mobile-wallet"
              ><span class="badge px-0 py-1"><i class="fas fa-coins text-lg"></i> {{ this.$filters.toCurrency(getMemberProfile.credit_total) }}</span></router-link>
              <div class="d-flex flex-column">
                <span><button class="bg-transparent border-0" @click="refreshCreditTotal"><i class="fas fa-redo text-white"></i></button></span>
                <!-- <div class="position-relative">
                  <p class="p-0 m-0"><button class="bg-transparent border-0" @click="$router.push('/cart-order')"><i class="fas fa-cart-arrow-down text-white"></i></button></p>
                  <span class="popup-cart">1</span>
                </div> -->
              </div>
          </li>
          <li>
            <button
              class="shadow-none navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navigation"
              aria-controls="navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="mt-2 navbar-toggler-icon">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
              </span>
            </button>
          </li>
        </ul>
        <ul v-if="!getMemberProfile" class="ml-auto d-flex d-xl-none align-items-center list-unstyled m-0">
          <li class="mx-0 nav-item d-flex gap-2 align-items-center">
            <router-link
              to="/signup"
              class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold border-r"
              >{{ $t('navbar.signUp') }}</router-link>
          </li>
          <li class="mx-0 nav-item d-flex gap-2 align-items-center">
            <router-link
              to="/signin"
              class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold login-menu"
              >{{ $t('navbar.signIn') }}</router-link>
          </li>
        </ul>
      </div>
      <div
        id="navigation"
        class="pt-3 pb-2 collapse navbar-collapse w-100 py-xl-0"
      >
        <ul class="mx-auto navbar-nav navbar-nav-hover">
          <li class="mx-2 nav-item" v-for="(menu, index) in translatedMenu" :key="index">
            <template v-if="menu.rules == '2'">
              <a
                :href="menu.path"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
              {{ $t(menu.title) }} 
              </a>
            </template>
            <template v-else>
              <router-link
                :to="menu.path"
                :class="['cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center']"
              >
                {{ $t(menu.title) }} 
              </router-link>
            </template>
          </li>
          <li class="mx-2 nav-item d-xl-none d-flex" v-if="getMemberProfile">
            <router-link
              to="/member/order"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center w-100 profile"
              ><span class="name">{{ $t('navbar.dashboard') }} {{ menu_auth[5] ? `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` : `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` }}</span></router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-xl-flex d-none align-items-center">
          <li v-if="getMemberProfile" class="nav-item d-flex justify-content-center align-items-center special-con shiny-effect">
            <!-- <div class="position-relative mt-3">
              <span class="p-0 m-0"><button class="bg-transparent border-0" @click="$router.push('/cart-order')"><i class="fas fa-cart-arrow-down text-white fs-5"></i></button></span>
              <span class="popup-cart">1</span>
            </div> -->
            <router-link
              v-if="getMemberProfile"
              to="/member/order"
              class="mb-0 me-1 text-lg text-white justify-content-between align-items-center d-inline-block w-100"
              >
              <div class="d-flex gap-2 justify-content-center align-items-center">
                <div>
                  <i v-if="getMemberProfile.username" class="fas fa-user-circle fs-2"></i>
                </div>
                <div>
                  <span class="profile">
                    <span class="name" style="font-size: 18px">{{ menu_auth[5] ? `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` : `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` }}</span> 
                  </span> 
                  <span class="wallet">{{ this.$filters.toCurrency(getMemberProfile.credit_total) }} 
                    <i v-if="getMemberProfile.credit_total" class="fas fa-coins text-lg"></i>
                  </span>
                </div>
              </div>
            </router-link>
            <span  class="mt-3"><button class="bg-transparent border-0" @click="refreshCreditTotal"><i class="fas fa-redo text-white fs-6"></i></button></span>
          </li>
          <li class="nav-item" v-if="!getMemberProfile">
            <router-link
              to="/signup"
              class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold border-r"
              >{{ $t('navbar.signUp') }}</router-link>
          </li>
          <li class="nav-item" v-if="!getMemberProfile">
            <router-link
              to="/signin"
              class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold login-menu"
              >{{ $t('navbar.signIn') }}</router-link>
          </li>
        </ul>
        <div class="nav-item dropdown language-switcher d-none d-xl-block py-1 position-absolute top-0 end-0 w-100 ">
          <div class=" container d-flex justify-content-between align-items-center">
            <ul class="list-unstyled d-flex gap-3 mb-0">
              <li><a class="text-white" href="https://www.facebook.com/GoldenbugGameShopping" target="_blank">Facebook</a></li>
              <li><a class="text-white" href="">{{ $t('menu.help')}}</a></li>
              <li><a class="text-white" href="https://lin.ee/dvfKmBo" target="_blank">{{ $t('menu.contact')}}</a></li>
            </ul>
            <div>
              <button
                class="btn dropdown-toggle m-0 p-0"
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
              {{ currentLang === 'th' ? 'ไทย/THB' : currentLang === 'en' ? 'US/USD' : currentLang === 'ph' ? 'PH/PHP' : '' }} <i class="fas fa-globe"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="languageDropdown">
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="changeLanguage('th')">ไทย / THB</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="changeLanguage('en')">US / USD</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="changeLanguage('ph')">PH / PHP</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
/*eslint-disable*/
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import Office from "../../components/Icon/Office.vue";
import Shop from "../../components/Icon/Shop.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import GettingStarted from "../../components/Icon/GettingStarted.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import Settings from "../../components/Icon/Settings.vue";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Navbar",
  components: {
    Office,
    Shop,
    CustomerSupport,
    Document,
    Spaceship,
    GettingStarted,
    Box3d,
    Settings
  },
  props: {
    btnBackground: {
      type: String,
      default: "",
    },
    isBlur: {
      type: String,
      default: "",
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downArrWhite,
      downArrBlack,
      currentLang: localStorage.getItem('language'),
      country: null,
    };
  },
  created() {
   const storedLang = localStorage.getItem('language');
   console.log('storedLang: ', storedLang);
  if (storedLang) {
    this.currentLang = storedLang;
    this.$i18n.locale = storedLang;
  } else {
    this.getCountryFromIP();
  }

    this.getProfile(); 
  },
  beforeMount() {
  },  
  // mounted() {
  //   this.$i18n.locale = this.currentLang; // ตั้งค่าภาษาที่เลือกเมื่อคอมโพเนนต์โหลด
  // },
  computed: {
    ...mapGetters(['getSetting', 'getMemberProfile']),
    menu_auth() {
      if (this.getSetting.menu_tem) {
        return this.getSetting?.menu_tem.filter(x => x.auth)
      }
      return []
    },
    menu_not_auth() {
      if (this.getSetting.menu_tem) {
        return this.getSetting?.menu_tem.filter(x => !x.auth)
      }
      return []
    },
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    },
    translatedMenu() {
      return this.menu_not_auth.map(menu => ({
        ...menu,
        title: this.getTranslationKey(menu.title)
      }));
    }
  },
  methods: {
    ...mapActions(['getProfile']),
    async refreshCreditTotal() {
    if (this.isRefreshing) {
      this.$swal({
        icon: "warning",
        title: "รอสักครู่",
        text: "ไม่สามารถอัพเดตหลายครั้งได้ รอ 5 วินาที"
      });
      return;
    }

    this.isRefreshing = true;

    try {
      await this.getProfile();
    } catch (error) {
      console.error("เกิดข้อผิดพลาดขณะอัปเดตยอดเครดิต:", error);
      this.$swal({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถอัปเดตยอดเครดิตได้"
      });
    } finally {
      setTimeout(() => {
        this.isRefreshing = false;
      }, 10000);
    }
  },
  getTranslationKey(title) {
    const keyMapping = {
      'หน้าหลัก': 'home',
      'เติมเกม ID/PASS': 'topup_id_pass',
      'เติมเกมอัตโนมัติ': 'topup_auto',
      'เติม เครดิต': 'coin_topup',
      'ข่าวสารและโปรโมชั่น': 'news_promotions',
      'ซื้อขายไอเท็ม': 'buy_sell_items',
      'ติดต่อเรา': 'contact_us',
      'โปรไฟล์': 'profile',
      'เข้าสู่ระบบ': 'sign_in'
    };
    return keyMapping[title] || title;
  },
  getCountryFromIP() {
    const API_KEY = 'c28d38236cd14b1cb69ce563ee0ea84f';
    const testIP = '103.136.108.0';

    // fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}`)
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}&ip=${testIP}`)
      .then(response => response.json())
      .then(data => {
        this.country = data.country_name;
        this.setLanguageBasedOnCountry();
      })
      .catch(error => {
        console.error("Error fetching location:", error);
        this.setLanguage('th');
      });
  },
  setLanguageBasedOnCountry() {
    let lang;
    switch (this.country) {
      case 'Thailand':
        lang = 'th';
        break;
      case 'Philippines':
        lang = 'ph';
        break;
      case 'United States':
        lang = 'en';
        break;
      default:
        lang = 'th';
    }
    console.log('lang: ', lang);
    this.setLanguage(lang);
  },

  setLanguage(lang) {
    this.currentLang = lang;
    this.$i18n.locale = lang;
    localStorage.setItem('language', lang);
  },

  changeLanguage(lang) {
    this.setLanguage(lang);
  }
  },
  watch: {
    '$route': async function(to, from) {
      if (!this.getMemberProfile) {
        await this.$store.dispatch('logOut');
        this.getProfile();
      }
    }
  }

};
</script>

<style scoped lang="scss">
.navbar {
  background-color: #000 !important;
}
.blur {
    box-shadow: inset 0px 0px 2px #ff5800;
    backdrop-filter:  blur(10px);
    background-color: rgb(45 45 45 / 35%) !important;
}
nav {
  span {
    &.profile,
    &.wallet {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
      gap: .5rem;
      text-align: right;
      padding-right: 5px;
    }
  }
  .profile {
    .name {
      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1;
      margin: 0;
    }
  }
  .topup-credit {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      z-index: -1;
    }
  }
  .mobile-wallet {
    background: rgb(81 81 81 / 80%);
    border-radius: 15px;
  }
  .login-menu {
    border-left: 1px solid #2c2c2c;
    padding-left: 1px;
  }
  .coin-box{
    width: 80px;
    font-size: 12px;
    border-radius: 15px;
    min-width: 10ch;
    background: rgb(81 81 81 / 80%);
    color: white;
    padding: 0.7rem 0;
  }
  .popup-cart{
    padding: 0 6px;
    border-radius: 50%;
    background-color: #e00b60;
    font-size: 10px;
    color: white;
    position: absolute;
    top: -3px;
    right: -3px;
  }
  .language-switcher {
    .dropdown-toggle {
      background-color: transparent !important;
      color: #fff !important;
      font-size: 16px;
      font-weight: normal !important;
    }
  
    .dropdown-menu {
      background-color: #333 !important; 
      color: #fff;
      right: 0 !important;
      left: unset !important;
      width: 120px !important;
    }
  
    .dropdown-item {
      color: #fff;
    }
    .dropdown-item:hover {
      color: #000000; 
    }

  }
  #languageDropdown::after {
    display: none;
  }
  .dropdown .dropdown-menu:before{
    display: none;
  }
  .dropdown{
    background-color: #1d1d1d;
  }
  
  /* Optionally, adjust the padding to make it look better */
  #languageDropdown {
    padding-right: 0;
  }
  .nav-item {
    position: relative; 
  }
  
  .nav-link {
    color: #fff !important;
    padding: 8px 15px;
    transition: color 0.3s ease; 
  }
  
  .nav-link:hover {
    color: #e00b60 !important; 
  }
  
  .nav-link::after {
    content: "";
    display: block;
    width: 100%; // ตั้งค่าให้เส้นเต็มความกว้าง
    height: 2px; // ความสูงของเส้น
    background: #e00b60; 
    position: absolute;
    bottom: -5px; // ปรับให้เส้นอยู่ต่ำกว่าข้อความ
    left: 0;
    transform: scaleX(0); // เริ่มต้นให้ซ่อนอยู่
    transition: transform 0.3s ease; // เอฟเฟกต์เรียบ
  }
  
  .nav-link:hover::after {
    transform: scaleX(1); // ขยายเส้นเมื่อ hover
  }
  
  .nav-link:hover {
    transform: scale(1.05); 
    transition: transform 0.3s ease-in-out;
  }
  .special-con {
    position: relative;
    overflow: hidden;
    background: linear-gradient(
      to bottom right,
      rgba(31, 31, 31, 0.711) 0%,
      rgba(100, 100, 100, 0.76) 50%,
      rgba(40, 40, 40, 0.803) 100%
    );
    border-radius: 15px;
    padding: 5px 5px 5px 15px;
    transition: all 0.3s ease;
  }
  
  .special-con:hover {
    transform: translateY(-5px);
    border-color: rgba(255, 255, 255, 0.5);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  
  .shiny-effect::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(45deg);
    animation: shine 2s infinite ease-in-out;
  }
  .language-switcher ul a{
    transition: .3s all ease-in-out;
  }
  .language-switcher ul a:hover{
    color: #e00b60 !important;
  }

  @keyframes clickAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%) rotate(45deg);
    }
    100% {
      transform: translateX(100%) rotate(45deg);
    }
  }

  @media (max-width: 758px) {
    .language-switcher {
      .dropdown-toggle {
        font-size: 12px !important;
      }
    }
    .coin-box{
      width: 60px;
      font-size: 10px;
      min-width: 8ch;
      padding: 0.5rem 0;
    }
    .mobile-wallet {
      font-size: 14px !important;
      padding: 0 15px 3px !important;
    }
  }

}
</style>