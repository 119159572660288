<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div class="page-header relative">
    <div class="container px-0 mt-7">
      <MainSliderHome :banners="this.getSetting.page?.home_page?.banners" />
    </div>
  </div>
  <div class="container pt-5">
    <div class="row">
      <div class="col-12 mb-4" v-if="getArticle[0]">
        <article>
          <header>
            <img class="img-fluid w-100 h-100" style="object-fit: contain;" :src="getArticle[0].cover_url" alt="">
          </header>
          <h1 class="text-danger mt-3 mb-0 article-subject fs-5">{{ getArticle[0].subject }}</h1>
          <time style="font-size: 12px;"><i class="fas fa-clock"></i> {{ moment(getArticle[0].created_date_iso).format('DD/MM/YYYY') }}</time>
          <div class="text-white description mt-3" v-html="getArticle[0].description"></div>
        </article>  
      </div>
    </div>
  </div>
  <div class="j-space my-3"></div>
  
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MainSliderHome from "@/views/pages/components/MainSliderHome.vue";

// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftButton from "@/components/SoftButton.vue";

import { mapMutations, mapGetters, mapActions } from "vuex";
import AOS from "aos";
import 'aos/dist/aos.css';
import Rellax from 'rellax';
import moment from "moment";

export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    MainSliderHome
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
  },
  data() {
    return {
      moment: moment
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    let fullParam = this.$route.params.article; 
    let articleId = fullParam.split('-').pop();
    let param = {
      article_id: articleId, 
      page_index: 1,
      limit_list_per_page: 1
    };
    this.fetchArticle(param);
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    AOS.init();
    if (document.getElementsByClassName('rellax')) {
      new Rellax('.rellax', {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true
      });

    //   rellax.destroy();
    }
  },
  computed: {
    ...mapGetters(['getSetting', 'getArticle'])
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['fetchArticle'])
  },
};
</script>

<style scoped lang="scss">
    // .container {
    //     max-width: 960px;
    // }
    header {
      img {
        aspect-ratio: 4 / 3;
        object-fit: cover;
        margin: 0 auto;
        display: flex;
        max-height: 450px;
      }
    }
    .article-subject {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
    .description {
      word-wrap: break-word;
    }
</style>