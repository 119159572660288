<template>
  <div>
    <navbar :is-blur="true" :dark-mode="true" />
    <div class="mt-8">
      <div class="row container mx-auto">
        <div class="card blur-dark-fade py-3">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <h1 class="text-white">{{ $t('dashboard.changePassword')}}</h1>
          </div>
          <div class="card-body pt-0">
            <div class="mb-3">
              <label class="form-label">{{ $t('signIn.username')}}</label>
              <input v-model="change_pass.username" class="form-control" placeholder="กรอกชื่อผู้ใช้งานของคุณ" type="text" @blur="onBlurUsername">
            </div>

            <div class="d-flex gap-5 align-items-center my-4">
              <p class="text-danger m-0 p-0 fw-bold">Email ของคุณ คือ : <span class="text-white">{{this.getDataUser ? this.getDataUser.email : ''}}</span></p>
              <button class="btn btn-danger m-0" @click="sendOTP()">ต้องการส่ง OTP ไปยังเมลนี้ คลิกที่นี่</button>
            </div>

            <div class="row mb-3">
              <label class="form-label mb-3">กรณากรอกรหัส OTP ที่คุณได้รับ</label>
              <div class="col-12 d-flex justify-content-start align-items-center">
                <div class="otp-container">
                  <input
                    v-for="(digit, index) in digits"
                    :key="index"
                    type="text"
                    maxlength="1"
                    v-model="digits[index]"
                    @input="onInput(index, $event)"
                    @keydown="onKeyDown(index, $event)"
                    @paste="onPaste"
                    :ref="getRefName(index)"
                    class="form-control otp-input"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">{{ $t('dashboard.newPassword') }}</label>
              <input v-model="change_pass.password" class="form-control" :placeholder="$t('dashboard.newPassword')" type="password">
            </div>
            
            <div class="mb-3">
              <label class="form-label">{{ $t('dashboard.confirmPassword') }}</label>
              <input v-model="change_pass.confirm_pass" class="form-control" :placeholder="$t('dashboard.confirmPassword')" type="password">
              <span v-if="change_pass.password && change_pass.confirm_pass && change_pass.password != change_pass.confirm_pass" class="text-danger text-sm">กรุณายืนยันรหัสผ่านใหม่ ให้ตรงกัน</span>
            </div>
            
            <h5 class="mt-5">{{ $t('passwordInstructions.title') }}</h5>
            <p class="text-muted mb-2 text-danger">{{ $t('passwordInstructions.subtitle') }}</p>
            <ul class="text-muted ps-4 mb-0 float-start">
              <li><span class="text-sm">{{ $t('passwordInstructions.rules[0]') }} </span></li>
              <li><span class="text-sm">{{ $t('passwordInstructions.rules[1]') }} </span></li>
              <li><span class="text-sm">{{ $t('passwordInstructions.rules[2]') }} </span></li>
              <li><span class="text-sm">{{ $t('passwordInstructions.rules[3]') }}</span></li>
            </ul>

            <div class="row float-end mt-6 mx-auto">
              <div class="col-md-6 col-12">
                <soft-button
                  class="float-end mb-0 btn btn-outline-danger fs-6"
                  style="width:250px"
                  variant="outline"
                  color="danger"
                  size="md"
                  @click.prevent="showModal()"
                  :disabled="change_pass.password && change_pass.confirm_pass && change_pass.password != change_pass.confirm_pass"
                > {{ $t('remember.contactAdmin')}}</soft-button>
              </div>
              <div class="col-md-6 col-12">
                <soft-button
                  class="float-end  mb-0 btn btn-outline-danger fs-6 me-3"
                  style="width:250px"
                  variant="outline"
                  color="danger"
                  size="md"
                  @click="saveChangePassword()"
                  :disabled="change_pass.password && change_pass.confirm_pass && change_pass.password != change_pass.confirm_pass"
                > {{ $t('passwordInstructions.confirmButton') }}</soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="jkanban-info-modal"
      class="modal fade"
      style="display: none"
      tabindex="-1"
      role="dialog"
    >
    <div class="modal-dialog">
      <div class="modal-content blur-dark-fade">
        <div class="modal-header">
          <h5 class="h5 modal-title text-white">{{ $t('signIn.rePassword2')}}</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body text-white">
          <a
            class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
            role="button"
            href="https://line.me/R/ti/p/@710oclzg?ts=06201834&oat_content=url"
            target="_blank"
            ><i class="fab fa-line"></i> @goldenbug
          </a>
          <a
            class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
            role="button"
            href="https://www.facebook.com/GoldenbugGameShopping"
            target="_blank"
            ><i class="fab fa-facebook"></i> GoldenBug
          </a>
          <div class="text-end">
            <button
              class="m-1 btn btn-secondary"
              @click.prevent="hideModal"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="jkanban-info-modal-backdrop"
    class="fixed inset-0 z-40 hidden bg-black opacity-50"
  ></div>
  
    <app-footer />
  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://uatapi.goldenbug.co/api";



export default {
  name: "Remember",
  components: {
    Navbar,
    AppFooter,
  },
  data() {
    return {
      digits: Array(6).fill(''), // 6 ช่อง
      usernameData: '',
      change_pass: {
        member_id: '',
        username: '',
        otp_vertify: '',
        password: '',
        confirm_pass: '',
      },
      pass_error: false,
      token: window.localStorage.getItem('jcr_token') || '',
      lastClickedTime: null, // ตัวแปรเพื่อเก็บเวลาที่ปุ่มถูกกดล่าสุด
      cooldownPeriod: 3 * 60 * 1000 // 1 นาที ในมิลลิวินาที
    };
  },
  // async created() {
  //   this.loadUsername();
  //   if (this.usernameData) {
  //     this.change_pass.username = this.usernameData;
  //     await this.$store.dispatch('getUsernameData', this.usernameData);
  //     console.log('this.getDataUser: ', this.getDataUser);
  //   }
  // },
  // mounted() {
  //   console.log('Mounted Refs:', this.$refs); // ตรวจสอบ ref หลังจาก DOM ถูก render
  // },
  computed: {
    ...mapGetters(['getDataUser','getsendOTPMail','getChangePassword']),
  },
  methods: {
    ...mapActions(['getSetting']),
    async showModal() {
      const bootstrap = this.$store.state.defaultStore.bootstrap;

      let jkanbanInfoModal = document.getElementById(
        "jkanban-info-modal"
      );

      var myModal = new bootstrap.Modal(jkanbanInfoModal, {
        show: true
      });
      myModal.show();
    },
    hideModal() {
      document
            .querySelector("#jkanban-info-modal")
            .classList.remove("show");
          document.querySelector("body").classList.remove("modal-open");
          document.querySelector("body").style = "";
          document.querySelector(".modal-backdrop").remove();
    },
    async saveChangePassword() {
      this.change_pass.otp_vertify = this.digits.join('');
      if (!this.change_pass.otp_vertify){
        return this.$swal({
            icon: "error",
            title: this.$t('remember.error'),
            text: "กรุณากรอก OTP",
            type: 'error',
        })
      } else if(this.change_pass.password == this.change_pass.confirm_pass){
        if (this.change_pass.confirm_pass.length < 8) {
          return this.$swal({
            icon: "error",
            title: this.$t('remember.error'),
            text: "กรุณากรอกรหัสให้มากกว่า 8 ตัว",
            type: 'error',
        })
        }else{
          this.$swal({
            title: this.$t('remember.confirmPasswordChange'),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t('remember.close'),
            confirmButtonText: this.$t('remember.confirm'),
            customClass: {
              confirmButton: "btn bg-danger",
              cancelButton: "btn bg-dark",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.change_pass.member_id = this.getDataUser?._id
                await this.$store.dispatch('RememberPassword', this.change_pass)
                .then((response) => {
                if (response.data.success) {
                return this.$swal({
                  icon: "success",
                  title: this.$t('remember.success'),
                  text: this.$t('remember.successText'),
                  type: 'success',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push("/signin");
                  } 
                });
              }else{
                return this.$swal({
                  icon: "error",
                  title: this.$t('remember.error'),
                  text: response.data.message,
                  type: 'error',
                });
              }
            })
            .catch(error => {
              console.log('An error occurred while updating profile: ', error);
            });
            } else if (
              result.dismiss === this.$swal.DismissReason.cancel
            ) {
              this.$swal.dismiss;
            }
          });
        }
      }else{
        this.$swal({
          title: this.$t('remember.validate'),
          icon: "warning",
        })
      }
    },
    async onInput(index, event) {
    const input = event.target;
    let value = input.value;
    // กรองข้อมูลที่กรอกเข้ามาให้เป็นตัวเลขเท่านั้น
    value = value.replace(/\D/g, '');
    if (value.length > 1) {
      value = value.split('');
      for (let i = 0; i < value.length; i++) {
        if (index + i < this.digits.length) {
          this.digits[index + i] = value[i];
        }
      }
    } else {
      this.digits[index] = value;
    }
    // ตรวจสอบถ้ามีค่าในช่องปัจจุบันและไม่ใช่ช่องสุดท้าย
    if (value && index < this.digits.length - 1) {
      await this.$nextTick();
      const refName = this.getRefName(index + 1);
      const nextInput = this.$refs[refName];
      if (nextInput) {
        nextInput.focus();
      } else {
        console.error(`Ref "${refName}" not found in $refs.`);
      }
    }
  },
    onKeyDown(index, event) {
      const input = event.target;
      // ตรวจสอบว่าเป็นปุ่ม Backspace และช่องปัจจุบันว่างเปล่า
      if (event.key === 'Backspace' && !input.value && index > 0) {
        this.$nextTick(() => {
          const previousInput = this.$refs[this.getRefName(index - 1)];
          if (previousInput) {
            previousInput.focus();
          }
        });
      }
    },
    getRefName(index) {
    return `otpInput${index}`;
    },
    onPaste(event) {
      event.preventDefault();
      const pastedData = event.clipboardData.getData('text');
      if (pastedData.length <= this.digits.length) {
        const newDigits = pastedData.split('');
        for (let i = 0; i < newDigits.length; i++) {
          this.digits[i] = newDigits[i];
        }
      }
    },
    async onBlurUsername() {
      if (this.change_pass.username) {
        await this.$store.dispatch('getUsernameData', this.change_pass.username)
        .then(() => {
          if (this.getDataUser && this.getDataUser.username) {
            this.$swal({
              title: 'สำเร็จ',
              text: `สวัสดีคุณ ${this.getDataUser.full_name} คุณสามารถเปลี่ยนรหัสได้`,
              icon: 'success',
              confirmButtonText: 'ตกลง'
            });
          } else {
            this.$swal({
              title: 'ไม่พบข้อมูล',
              text: 'Username นี้ไม่มีในระบบ กรุณาใช้ username อื่น',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            });
          }
        })
        .catch(error => {
          console.error('An error occurred while fetching username data:', error);
          this.$swal({
            title: 'ข้อผิดพลาด',
            text: 'เกิดข้อผิดพลาดในการตรวจสอบข้อมูล Username',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        });
      }
    },
    async sendOTP() {
      // ดึงข้อมูลเวลาที่ปุ่มถูกกดล่าสุดจาก localStorage
      const lastClickedTime = parseInt(localStorage.getItem('lastClickedTime'), 10) || 0;
      const currentTime = new Date().getTime();
      
      if (lastClickedTime && (currentTime - lastClickedTime < this.cooldownPeriod)) {
        // ถ้ายังไม่ครบ 3 นาที ให้แสดงข้อความเตือน
        const remainingTime = Math.ceil((this.cooldownPeriod - (currentTime - lastClickedTime)) / 1000);
        this.$swal({
          icon: "warning",
          title: "กรุณารอสักครู่",
          text: `คุณสามารถกดปุ่มได้อีก ${remainingTime} วินาที`
        });
        return;
      }

      // อัพเดตเวลาที่ปุ่มถูกกดใน localStorage
      localStorage.setItem('lastClickedTime', currentTime);

      try {
        // เรียกใช้ action เพื่อส่ง OTP
        const response = await this.$store.dispatch('sendMailOTP', this.getDataUser.email);
        if (response.success) {
          this.$swal({
            icon: "success",
            title: this.$t('successTitle'),
            text: response.message,
            type: "success"
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t('errorTitle'),
            text: response.message || "ไม่สามารถทำรายการได้ในขณะนี้",
            type: "error"
          });
        }
      } catch (error) {
        console.error("เกิดข้อผิดพลาด:", error);
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำรายการได้"
        });
      }
    }
  }
};
</script>

<style scoped>
.form-label {
  color: white; 
  font-size: 1rem;
}
* {
  color: #e3e3e3;
}
.otp-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

.otp-input {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 1rem;
}
@media (max-width: 768px) {
  h1{
    font-size: 24px;
  }
  .form-label {
    font-size: 14px;
  }
  .d-flex {
  display: block !important;
  text-align: center;
  }
  .d-flex p,
  .d-flex span {
    font-size: 14px !important;
  }
  .btn-danger{
    margin-top: 10px  !important;
    padding: 10px !important;
  }
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin-right: 0.2rem;
  }
  .float-end{
    margin: auto;
    width: 100% !important;
    float: none;
  }
  .btn-outline-danger{
    margin-bottom: 1rem !important;
    font-size: 12px!important;
    padding: 10px !important;

  }
  .me-3{
    margin: 0 !important;
  }
  .mt-6{
    margin-top: 2rem !important;
  }
}
</style>
