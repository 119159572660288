<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div
    class="page-header relative"
    v-if="
      this.getSetting.page.topup.banners &&
      this.getSetting.page.topup.banners.length >= 4
    "
  >
    <div class="container-fluid px-0 mt-8">
      <MainSlider :banners="this.getSetting.page.topup.banners" />
    </div>
  </div>

  <div
    class="container mt-9"
    v-if="
      this.getSetting.page.topup.banners &&
      this.getSetting.page.topup.banners.length < 4
    "
  >
    <div
      id="carouselExampleIndicators3"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <ol
        class="carousel-indicators"
        v-if="this.getSetting.page.topup.banners.length > 1"
      >
        <li
          data-bs-target="#carouselExampleIndicators3"
          v-for="(slide, index) in this.getSetting.page.topup.banners"
          :key="index"
          :data-bs-slide-to="index"
          :class="index == 0 ? 'active' : ''"
        ></li>
      </ol>
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(slide, index) in this.getSetting.page.topup.banners"
          :key="index"
          :class="index == 0 ? 'active' : ''"
        >
          <a v-if="slide.url" :href="slide.url">
            <img class="d-block w-100" :src="slide.img_url" alt="" />
          </a>
          <img v-else class="d-block w-100" :src="slide.img_url" alt="" />
        </div>
      </div>
      <a
        v-if="this.getSetting.page.topup.banners.length > 1"
        class="carousel-control-prev"
        href="#carouselExampleIndicators3"
        role="button"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        v-if="this.getSetting.page.topup.banners.length > 1"
        class="carousel-control-next"
        href="#carouselExampleIndicators3"
        role="button"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="container pt-5">
    <div class="j-space my-3"></div>
    <div class="row justify-content-center">

      <div
        class="col-lg-6 col-md-6 mb-4"
        v-if="getSettingCMSAll && getSettingCMSAll.allow_use_topup_credit"
      >
        <div class="card text-white blur-dark-fade sticky-top z-index-1 top-10">
          <div class="card-body">
            <form id="frm_promptpay" @submit.prevent="confirmDigit()">
              <div class="mb-5" v-if="!getMemberProfile">
                <div class="d-flex align-items-center mb-3">
                  <span class="boxNumber">1.</span>
                  <strong>{{ $t('coinTopup.signup') }}</strong>
                </div>
                <!-- <div class="input-wrapper">
                  <input
                    type="text"
                    v-model="inputValue"
                    class="form-control"
                    placeholder="ระบุ ID ของคุณที่นี่"
                  />
                  <span v-if="!inputValue" class="question-mark">?</span>
                </div> -->
                <button
                  type="button"
                  class="btn btn-outline-danger mt-3 mb-0 fs-6 w-100"
                  @click="$router.push('/signup')"
                >
                {{ $t('coinTopup.membership') }}
                </button>
              </div>

              <div class="mb-5" v-if="getMemberProfile">
                <div class="d-flex align-items-center mb-3">
                  <span class="boxNumber">1.</span>
                  <strong>{{ $t('coinTopup.proForYou') }}</strong>
                </div>
                <div class="col-12 pt-2" v-if="promotions.length <= 0">
                  <strong class="text-danger text-center">
                    {{ $t('coinTopup.notPro') }}
                  </strong>
                </div>
                <div
                  class="col-12 my-2 mb-3"
                  v-for="(item, index) in promotions"
                  :key="index"
                >
                  <div
                    class="special-con d-flex shiny-effect"
                    @click="togglePackage(item)"
                  >
                    <div class="col-2 col-md-2 d-flex align-items-center me-3">
                      <img
                        class="w-70 img-fluid"
                        src="../../../public/logo.png"
                        alt=""
                      />
                    </div>
                    <div class="text-con col-9 col-md-9 d-flex flex-column">
                      <p class="text-white pt-3" style="font-size: 14px;">
                        {{ item.name_promotion }}
                      </p>
                      <div
                        class="use-rights-btn text-white bg-transparent fw-bold p-1 mb-0 align-self-end"
                        style="font-size: 12px;"
                      >
                        <span class="btn-text">{{ item.showPackage ? $t('close') : $t('coinTopup.view') }}</span>
                        <i
                          class="fas"
                          :class="
                            item.showPackage
                              ? ' fa-chevron-up'
                              : ' fa-chevron-down'
                          "
                        ></i>
                      </div>
                    </div>
                  </div>

                  <transition name="slide-fade">
                    <div
                      v-if="item.showPackage"
                      class="package-details"
                      :class="
                        selectedPromotion === item._id ? 'submit-pro' : ''
                      "
                      @click="selectPromotion(item)"
                    >
                      <span class="text-danger fw-bold" style="font-size: 14px;">{{ $t('coinTopup.packageDetail') }}</span>
                      <p
                        v-if="item.promotion_type == 0 && !item.promotion_start"
                      >
                        <span
                          >{{ $t('coinTopup.conditionPro1') }}</span
                        >
                      </p>
                      <p
                        v-if="item.promotion_type == 1 && !item.promotion_start"
                      >
                        <span
                          >{{ $t('coinTopup.conditionPro2') }}</span
                        >
                      </p>
                      <p
                        v-if="item.promotion_type == 2 && !item.promotion_start"
                      >
                        <span
                          >{{ $t('coinTopup.conditionPro3') }}</span
                        >
                      </p>
                      <p
                        v-if="item.promotion_type == 3 && !item.promotion_start"
                      >
                        <span
                          >{{ $t('coinTopup.conditionPro4') }}</span
                        >
                      </p>
                      <p
                        v-if="item.promotion_type == 4 && !item.promotion_start"
                      >
                        <span
                          >{{ $t('coinTopup.conditionPro5') }}
                          {{ item.count_topup_condition }} {{ $t('coinTopup.count') }}</span
                        >
                      </p>
                      <p
                        v-if="item.promotion_type == 5 && !item.promotion_start"
                      >
                        <span
                          >{{ $t('coinTopup.conditionPro5') }}
                          {{ item.count_total_point_condition }} {{ $t('coinTopup.bath') }}
                          {{ $t('coinTopup.conditionPro7') }}</span
                        >
                      </p>
                      <p v-if="item.promotion_start && item.promotion_expire">
                        <span>
                          {{ $t('coinTopup.conditionPro6') }}
                          {{ formatDate(item.promotion_start) }} {{ $t('coinTopup.into') }}
                          {{
                            formatDate(item.promotion_expire)
                          }}
                          {{ $t('coinTopup.expirePro') }}
                        </span>
                      </p>
                      <p class="mb-0">
                        <span>{{ $t('coinTopup.price') }}:</span> {{ item.price }} ฿
                      </p>
                      <div class="d-flex justify-content-between">
                        <p class="text-success">
                          <span>{{ $t('coinTopup.bonus') }}:</span> +
                          {{ item.topup_point - item.price }} GBD Coins
                        </p>
                        <button
                          class="border-0 bg-transparent text-info fw-bolder"
                          style="font-size: 14px;"
                        >
                        {{ $t('coinTopup.usePro') }}
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>

              <div class="mb-5">
                <div class="d-flex align-items-center mb-3">
                  <span class="boxNumber">2.</span>
                  <!-- <h3>เลือก แพ็คเกจ</h3> -->
                  <strong>{{ $t('coinTopup.amount') }}</strong>
                </div>
                <div class="row" v-if="getMemberProfile && language != 'ph'">
                  <div
                    v-for="(item, index) in this.getSetting.promotion"
                    :key="index"
                    class="col-md-6 col-sm-6 col-6 mb-4 col-lg-4"
                  >
                    <div
                      class="box"
                      :class="
                        selectPackage === item.id
                          ? 'btn-active'
                          : 'btn-unactive'
                      "
                      @click="selectPackages(item)"
                      v-if="item.active == true"
                    >
                      <div v-if="item.banner_flag == 1" class="recommended-tag">
                        {{ $t('coinTopup.recommend') }}
                      </div>
                      <div v-if="item.banner_flag == 2" class="recommended-tag">
                        {{ $t('coinTopup.hot') }}
                      </div>
                      <span>{{ item.name }}</span>
                      <span>฿{{ item.price }}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="fix-amount" style="color: grey; font-size: 14px">
                    {{ $t('coinTopup.amountPayment') }}
                  </p>
                  <div class="mb-3" style="position: relative" ref="myScroll">
                    <input
                      type="text"
                      inputmode="decimal"
                      id="creadit_total_topup"
                      v-model="topup.creadit_total_topup"
                      name="creadit_total_topup"
                      class="form-control"
                      :placeholder="$t('coinTopup.minimunAmount')"
                      @focus="checkFocus()"
                      :style="{
                        display:
                          selectPackage || (!selectPackage && selectedPromotion)
                            ? 'none'
                            : 'block',
                      }"
                      @blur="validateDecimal"
                    />
                    <button
                      type="button"
                      class="btn btn-outline-danger mt-3 mb-0 fs-5 w-100"
                      :style="{
                        display:
                          selectPackage || (!selectPackage && selectedPromotion)
                            ? 'block'
                            : 'none',
                      }"
                      @click="showInput"
                    >
                    {{ $t('coinTopup.amountClick') }}
                    </button>
                    <!-- <span
                      v-if="!inputValue"
                      class="editMoney"
                      :style="{ display: selectPackage ? 'none' : 'block' }"
                      @click="formatMoney()"
                      >แก้ไขยอดเงิน</span
                    > -->
                  </div>
                </div>
              </div>

              <div class="mb-5">
                <div class="d-flex align-items-center mb-3">
                  <span class="boxNumber">3.</span>
                  <strong>{{ $t('coinTopup.payment') }}</strong>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="row" v-if="banks && banks.length">
                      <div class="col-6 mb-3" v-if="banks[0]">
                        <div
                          class="payment-method-card"
                          :class="{ selected: selectedPaymentMethod == 'bank' }"
                          @click.prevent="selectPaymentMethod(banks, 'bank')"
                        >
                          <i class="fas fa-university"></i>
                          <span>{{ $t('coinTopup.bank') }}</span>
                          <i
                            v-if="selectedPaymentMethod == 'bank'"
                            class="fas fa-check-circle check-icon"
                          ></i>
                        </div>
                      </div>
                      <div class="col-6 mb-3" v-if="banks[1]">
                        <div
                          class="payment-method-card"
                          :class="{
                            selected: selectedPaymentMethod == 'wallet',
                          }"
                          @click.prevent="selectPaymentMethod(banks, 'wallet')"
                        >
                          <i class="fas fa-wallet"></i>
                          <span>{{ $t('coinTopup.wallet') }}</span>
                          <i
                            v-if="selectedPaymentMethod == 'wallet'"
                            class="fas fa-check-circle check-icon"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  class="row mt-4"
                  v-if="
                    confirm &&
                    banks &&
                    banks.length &&
                    banks[0] &&
                    selectedPaymentMethod
                  "
                >
                  <div
                    class="col-12"
                    v-if="
                      confirm &&
                      (this.selectPackage ||
                        this.selectedPromotion ||
                        this.inputForm)
                    "
                  >
                    <label class="form-label text-danger fs-5"
                      >{{ $t('coinTopup.tran') }}</label
                    >
                    <span
                      class="text-white d-flex justify-content-start gap-3 align-items-center fs-3 fw-bold ms-1"
                    >
                      {{
                        this.$filters.toCurrency(+this.topup.creadit_total_topup)
                      }} {{ this.language == 'en' ? '$' : this.language == 'ph' ? '₱' : 'th' }}
                      <span 
                        ><button
                          @click.prevent="
                            copyToClipboard(
                              this.topup.creadit_total_topup,
                              $t('coinTopup,readyCopy')
                            )
                          "
                          class="btn btn-xs btn-outline-white m-0 p-1"
                        >
                          <i class="fas fa-copy"></i> {{ $t('coinTopup.copy') }}
                        </button></span
                      >
                    </span>
                  </div>
                  <!-- <label class="form-label text-danger text-sm" for="promptpay_amount">เลือกวิธีชำระเงิน</label> -->
                  <div class="col-md-12 mb-3"  v-if="this.selectedPaymentMethod == 'bank'">
                    <span class="mb-2 text-white fs-6 fw-bold ms-1">{{ $t('coinTopup.tranAttach') }}</span>
                    <div class="d-flex gap-3 align-items-center">
                      <div class="bank-logo">
                        <img
                          class="img-fluid"
                          width="60"
                          :src="dataBank.url_img"
                          alt=""
                        />
                      </div>
                      <div class="bank-detail">
                        <span class="m-0 text-white fs-6 fw-bold">{{ dataBank.bank_name }}</span>
                        <span
                          class="m-0 text-white d-flex justify-content-between align-items-center fs-6 fw-bold"
                        >
                          {{ dataBank.account_no }}
                          <button
                            @click.prevent="copyBank(dataBank)"
                            class="btn btn-xs btn-outline-white m-0 p-1 ms-2"
                          >
                            <i class="fas fa-copy"></i> {{ $t('coinTopup.copy') }}
                          </button>
                        </span>
                        <span class="m-0 text-white fs-6 fw-bold">
                          {{ dataBank.account_name }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        topup.bank_id == dataBank._id &&
                        (dataBank.code === 3 || dataBank.qr_code_img)
                      "
                    ></div>
                  </div>

                  <div class="col-md-12 mb-3" v-if="this.selectedPaymentMethod == 'wallet'">
                    <span class="mb-2 text-whit fs-6 fw-bold ms-1">{{ $t('coinTopup.tranAttach') }}</span>
                    <div class="d-flex gap-3 align-items-center">
                      <div class="bank-logo">
                        <img
                          class="img-fluid"
                          width="60"
                          :src="dataWallet.url_img"
                          alt=""
                        />
                      </div>
                      <div class="bank-detail">
                        <span class="m-0 text-white fs-6 fw-bold">{{ dataWallet.bank_name }}</span>
                        <span
                          class="m-0 text-white d-flex justify-content-between align-items-center fs-6 fw-bold"
                        >
                          {{ dataWallet.account_no }}
                          <button
                            @click.prevent="copyBank(dataWallet)"
                            class="btn btn-xs btn-outline-white m-0 p-1 ms-2"
                          >
                            <i class="fas fa-copy"></i> {{ $t('coinTopup.copy') }}
                          </button>
                        </span>
                        <span class="m-0 text-white fs-6 fw-bold">
                          {{ dataWallet.account_name }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        topup.bank_id == dataWallet._id &&
                        (dataWallet.code === 3 || dataWallet.qr_code_img)
                      "
                    ></div>
                  </div>

                  
                </div>
                <div
                  class="row mb-3"
                  v-if="this.confirm && qrCodeImage && selectedPaymentMethod && this.selectedPaymentMethod == 'bank'"
                >
                  <div class="col-md-4 col-6">
                    <!-- QR Code container with v-html -->
                    <div
                      v-html="qrCodeImage"
                      class="w-100"
                      @click="downloadQRCode"
                      :class="{ 'animate-download': animationClass }"
                      style="cursor: pointer"
                    ></div>

                    <!-- Hidden link for download -->
                    <a
                      ref="downloadLink"
                      :href="downloadUrl"
                      download="qrcode.svg"
                      style="display: none"
                    ></a>
                  </div>
                  <div class="col-md-8 mt-3 col-6">
                    <button
                      class="btn btn-xs btn-outline-white m-0 p-1"
                      @click="downloadQRCode"
                    >
                      <i class="fas fa-download"></i> Download QRCode
                    </button>
                  </div>
                </div>

                <div class="row mb-3" v-if="this.confirm && qrCodeImage && selectedPaymentMethod && this.selectedPaymentMethod == 'wallet'">
                  <div class="col-md-4 col-6">
                    <!-- QR Code container -->
                    <img
                      :src="QrCodeTrue"
                      alt="QR Code"
                      class="w-100"
                      @click="downloadQRCodeTrue"
                      :class="{ 'animate-download': animationClass }"
                      style="cursor: pointer;"
                    />
                
                    <!-- Hidden link for download -->
                    <a ref="downloadLinkTrue" :href="downloadUrlTrue" download="qrcode.jpg" style="display: none;"></a>
                  </div>
                  <div class="col-md-8 mt-3 col-6">
                    <button
                      class="btn btn-xs btn-outline-white m-0 p-1"
                      @click="downloadQRCodeTrue"
                    >
                      <i class="fas fa-download"></i> Download QRCode
                    </button>
                  </div>
                </div>

                <div
                  class="row"
                  :class="confirm && selectedPaymentMethod ? '' : 'd-none'"
                >
                  <div class="col-12 mb-3">
                    <label for="slip">{{ $t('coinTopup.attach') }}</label>
                    <form
                      id="dropzone2"
                      ref="dropzone2"
                      :action="`${API_URL}/file/upload`"
                      class="form-control dropzone d-flex justify-content-start align-items-center text-center"
                      @click="resetSlip()"
                    >
                      <div class="fallback">
                        <input name="file" type="file" />
                      </div>
                      <a
                        v-if="topup.receipt_url"
                        class="order-2 dz-remove btn btn-danger z-index-1 cursor-pointer mt-3"
                        role="button"
                        @click="resetSlip()"
                        >{{ $t('coinTopup.deleteFile') }}</a
                      >
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <button
                    type="button"
                    class="btn btn-outline-danger fs-6 w-100"
                    @click="confirmPayment()"
                  >
                  {{ $t('coinTopup.submitForm') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12 mb-4" v-else>
        <div class="card text-white blur-dark-fade sticky-top z-index-1 top-10">
          <div class="card-body text-center">
            <i class="fas fa-exclamation-triangle fa-3x text-danger"></i>
            <p class="mt-3">{{ $t('coinTopup.closeCoine')}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div
          class="container-fluid px-0 feature-section sticky-top z-index-1 top-10"
        >
          <div class="container mb-5">
            <div class="row mb-5">
              <div class="col-12 col-md-12 d-flex justify-content-center align-items-center">
                <img
                  class="img-fluid w-100 h-auto rounded-3"
                  src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg"
                  alt=""
                />
              </div>
            </div>
            <p class="text-center text-white mb-4 fs-4 fw-bolder">
              {{ $t('coinTopup.whyGolden')}}
            </p>
            <div class="row">
              <div class="col-6 text-center mb-3">
                <img
                  class="mb-2 w-30"
                  src="../../assets/img/coin-topup/เงินเข้าไว.png"
                  alt=""
                  srcset=""
                />
                <p class="mb-1 text-danger fs-6 fw-bold">{{ $t('coinTopup.title1')}}</p>
                <p class="m-0 text-light" style="font-size: 12px;">
                  {{ $t('coinTopup.desc1')}}
                </p>
              </div>
              <div class="col-6 text-center mb-3">
                <img
                  class="mb-2 w-30"
                  src="../../assets/img/coin-topup/24ชั่วโมง.png"
                  alt=""
                  srcset=""
                />
                <p class="mb-1 text-danger fs-6 fw-bold">{{ $t('coinTopup.title2')}}</p>
                <p class="m-0 text-light" style="font-size: 12px;">
                  {{ $t('coinTopup.desc2')}}
                </p>
              </div>
              <div class="col-6 text-center mb-3">
                <img
                  class="mb-2 w-30"
                  src="../../assets/img/coin-topup/ปลอดภัย.png"
                  alt=""
                  srcset=""
                />
                <p class="mb-1 text-danger fs-6 fw-bold">{{ $t('coinTopup.title3')}}</p>
                <p class="m-0 text-light" style="font-size: 12px;">
                  {{ $t('coinTopup.desc3')}}
                </p>
              </div>
              <div class="col-6 text-center mb-3">
                <img
                  class="mb-2 w-30"
                  src="../../assets/img/coin-topup/มั่นใจ.png"
                  alt=""
                  srcset=""
                />
                <p class="mb-1 text-danger fs-6 fw-bold">{{ $t('coinTopup.title4')}}</p>
                <p class="m-0 text-light" style="font-size: 12px;">
                  {{ $t('coinTopup.desc4')}}
                </p>
              </div>
            </div>
          </div>
          <div class="container py-3 text-white aos-section">
            <div class="row">
              <div
                class="col-md-6"
              >
                <h1 class="text-lg text-white">
                  {{ getSetting?.page?.home_page.section[3].row[0].title }}
                </h1>
                <h2 class="text-danger w-lg-80 text-xxl lh-1 fs-5">
                  {{ getSetting?.page?.home_page.section[3].row[0].sub_title }}
                </h2>
                <p class="text-white" style="font-size: 12px;">
                  {{
                    getSetting?.page?.home_page.section[3].row[0].description
                  }}
                </p>
                <div
                  class="row"
                  v-if="
                    games &&
                    getSetting?.page?.home_page.section[3].row[0]
                      .display_items &&
                    getSetting?.page?.home_page.section[3].row[0].display_items
                      .length
                  "
                >
                  <div
                    class="col-6 mobile-side p-1"
                    v-for="(item, index) in getSetting?.page?.home_page
                      .section[3].row[0].display_items"
                    :key="index"
                  >
                    <router-link
                      data-scroll
                      :to="`/game-topup/${item.id}`"
                      class="card card-game border-none blur-dark cursor-pointer"
                    >
                      <img :src="item.icon_url" alt="" class="icon-game" />
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 mt-5 mt-md-3"
              >
                <img
                  class="w-100"
                  :src="
                    getSetting?.page?.home_page.section[3].row[1].img_url ||
                    '@/assets/img/game/game-section-2.png'
                  "
                  alt=""
                />
                <div class="row">
                  <div class="col-6 mx-auto mt-5 text-center">
                    <router-link
                      :to="`/game-topup/`"
                      class="btn btn-lg px-4 text-lg btn-danger mx-auto mb-0"
                      >{{
                        getSetting?.page?.home_page.section[3].row[1]
                          .cta_button || "ซื้อเลย"
                      }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="getMemberProfile" class="col-lg-12">
        <div class="card blur-dark-fade">
          <div class="card-body">
            <h3 class="text-danger fs-5">{{ $t('coinTopup.history')}}</h3>
            <div class="table-responsive">
              <table class="table align-items-center mb-0 text-white mb-4">
                <thead>
                  <tr>
                    <th style="font-size: 14px;">{{ $t('coinTopup.ref')}}</th>
                    <th style="font-size: 14px;">{{ $t('coinTopup.tablePay')}}</th>
                    <th style="font-size: 14px;">{{ $t('coinTopup.timePay')}}</th>
                    <th style="font-size: 14px;">{{ $t('coinTopup.remark')}}</th>
                    <th class="text-center" style="font-size: 14px;">{{ $t('coinTopup.status')}}</th>
                  </tr>
                </thead>
                <tbody v-if="paginatedData.length">
                  <tr v-for="(item, index) in paginatedData" :key="index">
                    <td class="text-table">{{ item._id }}</td>
                    <td class="text-table">
                      {{ this.$filters.toCurrency(item.creadit_total_topup) }}
                    </td>
                    <td class="text-table">
                      {{
                        moment(item.created_date_iso).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )
                      }}
                    </td>
                    <td class="text-table">{{ item.remark_admin }}</td>
                    <td
                      class="text-center text-table"
                      v-html="checkTopupStatus(item.status_topup_credit)"
                    ></td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center align-items-center w-100">
                <nav aria-label="Page navigation example" style="width: 400px">
                  <ul class="pagination justify-content-center w-100">
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === 1 }"
                    >
                      <a
                        class="page-link"
                        href="#"
                        @click.prevent="changePage(currentPage - 1)"
                        ><i class="fa fa-backward"></i
                      ></a>
                    </li>
                    <li
                      v-for="page in totalPages"
                      :key="page"
                      class="page-item"
                      :class="{ active: currentPage === page }"
                    >
                      <a
                        class="page-link"
                        href="#"
                        @click.prevent="changePage(page)"
                        >{{ page }}</a
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: currentPage === totalPages }"
                    >
                      <a
                        class="page-link"
                        href="#"
                        @click.prevent="changePage(currentPage + 1)"
                        ><i class="fa fa-forward"></i
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="j-space my-5"></div>
  </div>
  <div class="j-space my-3"></div>

  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import QrCodeTrue from '../../assets/img/qrcode-true.jpg'
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftButton from "@/components/SoftButton.vue";
import Dropzone from "dropzone";
// const API_ROOT_URL = process.env.VUE_APP_API_URL;
const API_ROOT_URL = "https://uatapi.goldenbug.co/api";

import { mapMutations, mapActions, mapGetters } from "vuex";
import AOS from "aos";
import "aos/dist/aos.css";
import Rellax from "rellax";
import moment from "moment";

export default {
  name: "CoinTopUp",
  components: {
    Navbar,
    AppFooter,
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
  },
  data() {
    return {
      topup: {
        creadit_total_topup: "",
        bank_id: "",
        receipt_url: "",
        _id: "",
        promotion_credit_id: null,
        // creadit_package_topup: "",
      },
      moment: moment,
      confirm: false,
      API_URL: API_ROOT_URL,
      token: window.localStorage.getItem("jcr_token") || null,
      slipDropzone: null,
      selectPackage: null, // ตั้งค่าเริ่มต้นให้ยังไม่มีแพ็กเกจถูกเลือก
      selectedPromotion: null,
      checkInput: false,
      currentPage: 1,
      perPage: 10,
      qrCodeImage: null,
      downloadUrl: "",
      animationClass: false,
      isSlideBoxActive: false,
      inputForm: false,
      selectedPaymentMethod: null,
      dataBank: null,
      dataWallet: null,
      QrCodeTrue,
      downloadUrlTrue: '',
      language: localStorage.getItem("language") || 'th',
      country: null,
    };
  },
  async created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.getCountryFromIP();
    this.getHistoryTopupCredit();
    await this.getSettingCMS().then(() => {
      const dataPromotion = `${this.getSetting.promotion}`;
    });
    if (this.getMemberProfile && this.getMemberProfile._id) {
      this.topup._id = this.getMemberProfile._id;
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    AOS.init();
    if (document.getElementsByClassName("rellax")) {
      new Rellax(".rellax", {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true,
      });

      //   rellax.destroy();
    }

    setTimeout(() => {
      Dropzone.autoDiscover = false;

      this.slipDropzone = new Dropzone(this.$refs.dropzone2, {
        // addRemoveLinks: true,
        dictDefaultMessage: this.$t('coinTopup.chooseSlip'),
        dictRemoveFile: this.$t('coinTopup.slipAgain'),
        maxFiles: 1,
        init: function () {
          this.on("maxfilesexceeded", async function (file) {
            await this.removeAllFiles();
            this.addFile(file);
          });
        },
        headers: {
          Authorization: this.token,
        },
        acceptedFiles: "image/*",
      });

      var self = this;
      this.slipDropzone.on("success", function (file, res) {
        try {
          const file_name = res.datas.file_name;
          self.topup.receipt_url = `https://uatapi.goldenbug.co/files/${file_name}`;
        } catch (error) {
          self.checkLogin();
        }
      });

      this.slipDropzone.on("error", (file, res) => {
        this.topup.receipt_url = "error";
        this.$swal({
          icon: "error",
          title: this.$t('coinTopup.slipTitleError'),
          text: this.$t('coinTopup.slipMessageError'),
          type: "warning",
        });
        this.removeFile(file);
      });
    }, 500);
  },
  computed: {
    ...mapGetters([
      "games",
      "gameBanner",
      "getSetting",
      "banks",
      "getMemberProfile",
      "topupTransaction",
      "getSettingCMSAll",
      "getQrCode",
      "promotions",
    ]),
    totalPages() {
      return Math.ceil(this.topupTransaction.length / this.perPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.topupTransaction.slice(start, end);
    },
  },
  // watch: {
  //   promotions(newPromotions) {
  //     console.log("Promotions updated:", newPromotions);
  //   }
  // },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions([
      "fetchBanks",
      "getHistoryTopupCredit",
      "getSettingCMS",
      "fetchPromotions",
    ]),
    // ...mapActions(['fetchGames']),
    resetSlip() {
      try {
        this.slipDropzone.removeAllFiles(true);
        this.topup.receipt_url = "";
      } catch (error) {
        console.log("error: " + error);
      }
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    checkTopupStatus(status) {
      let badge, text;
      if (status == 0) {
        badge = "danger";
        text = this.$t('coinTopup.pending');
      }
      if (status == 1) {
        badge = "warning";
        text = this.$t('coinTopup.inProcess');
      }
      if (status == 2) {
        badge = "success";
        text = this.$t('coinTopup.success');
      }
      if (status == 9) {
        badge = "danger";
        text = this.$t('coinTopup.fail');
      }

      return `<label class="badge badge-${badge} m-0">${text}</label>`;
    },
    confirmPayment() {
      if (this.validateForm()) {
        this.checkLogin();
        this.$swal({
          title: this.$t('coinTopup.confirmTitle'),
          text: this.$t('coinTopup.confirmText'),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t('close'),
          confirmButtonText: this.$t('coinTopup.confirmButtonText'),
          customClass: {
            confirmButton: "btn bg-danger",
            cancelButton: "btn bg-dark",
          },
          buttonsStyling: false,
        }).then((result) => {
          // console.log('result: ', result);
          if (result.isConfirmed) {
            this.submitForm();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      }
    },
    validateForm() {
      if (
        this.selectedPromotion ||
        this.selectPackage ||
        (this.inputForm && this.topup.creadit_total_topup != "")
      ) {
        if (this.topup.creadit_total_topup == "") {
          this.$swal({
            icon: "warning",
            title: this.$t('coinTopup.validateFormTopup'),
            type: "warning",
          });
          this.confirm = false;
          return false;
        }
        if (this.topup.receipt_url == "error") {
          this.$swal({
            icon: "warning",
            title: this.$t('important'),
            text: this.$t('coinTopup.validateFormSlip'),
            type: "warning",
          });
          return false;
        }
        if (this.topup.receipt_url == "") {
          this.$swal({
            icon: "warning",
            title: this.$t('coinTopup.validateFormSlipUrl'),
            type: "warning",
          });
          return false;
        }
        return true;
      } else {
        if (this.selectPackage == "") {
          this.$swal({
            icon: "warning",
            title: this.$t('coinTopup.validateFormPackage'),
            type: "warning",
          });
          return false;
        }
        if (this.topup.receipt_url == "error") {
          this.$swal({
            icon: "warning",
            title: this.$t('important'),
            text: this.$t('coinTopup.validateFormSlip'),
            type: "warning",
          });
          return false;
        }
        if (this.topup.receipt_url == "") {
          this.$swal({
            icon: "warning",
            title: this.$t('coinTopup.validateFormSlipUrl'),
            type: "warning",
          });
          return false;
        }
        if (this.topup.creadit_total_topup != "0") {
          this.$swal({
            icon: "warning",
            title: this.$t('coinTopup.validateFormTopup'),
            type: "warning",
          });
          return false;
        }
        return true;
      }
    },
    async confirmDigit() {
      try {
        if (this.selectedPromotion || this.selectPackage) {
          this.confirm = true;
          const floatNumber = Number(this.topup.creadit_total_topup).toFixed(2);
          this.topup.creadit_total_topup = floatNumber;

          // เรียกใช้ genQrCode และรอให้ทำงานเสร็จ
          await this.$store.dispatch("genQrCode", {
            amount: this.topup.creadit_total_topup,
          });

          // รับค่าของ QR code
          const qrCodeSvg = this.$store.getters.getQrCode;
          this.qrCodeImage = qrCodeSvg;
        } else if (this.inputForm) {
          if (this.topup.creadit_total_topup === "") {
            await this.$swal({
              icon: "warning",
              title: this.$t('coinTopup.validateFormTopup'),
              type: "warning",
            });
            return false;
          }
          const totalTopup = this.topup.creadit_total_topup.trim();
          if (totalTopup === "" || isNaN(totalTopup)) {
            await this.$swal({
              icon: "warning",
              title: this.$t('coinTopup.topupIsNaN'),
              type: "warning",
            });
            return false;
          }
          if (totalTopup < 1) {
            await this.$swal({
              icon: "warning",
              title: this.$t('coinTopup.topUpMinimun'),
              type: "warning",
            });
            this.topup.creadit_total_topup = "";
            this.confirm = false;
            return false;
          }

          if (totalTopup > 10000000) {
            await this.$swal({
              icon: "warning",
              title: this.$t('coinTopup.topUpMaximun'),
              type: "warning",
            });
            return false;
          }
        } else {
          this.confirm = true;
          const floatNumber = Number(this.topup.creadit_total_topup).toFixed(2);
          this.topup.creadit_total_topup = floatNumber;

          // เรียกใช้ genQrCode และรอให้ทำงานเสร็จ
          await this.$store.dispatch("genQrCode", {
            amount: this.topup.creadit_total_topup,
          });

          // รับค่าของ QR code
          const qrCodeSvg = this.$store.getters.getQrCode;
          this.qrCodeImage = qrCodeSvg;
        }
      } catch (error) {
        console.error("Error in confirmDigit:", error);
        await this.$swal({
          icon: "error",
          title: this.$t('errorTitle'),
          text: "ไม่สามารถดำเนินการได้ในขณะนี้",
        });
      }
    },

    submitForm() {
      this.$store
        .dispatch("topupCredit", this.topup)
        .then((response) => {
          // ตรวจสอบค่า success ที่ได้รับจาก API
          if (response.data.success) {
            // กรณีที่ API รายงานว่าสำเร็จ
            this.$swal({
              icon: "success",
              title: this.$t('successTitle'),
              text: this.$t('coinTopup.successMessage'),
              type: "success",
            });
            this.$router.push("/member/order");
          } else {
            // กรณีที่ API รายงานว่าไม่สำเร็จ
            this.$swal({
              icon: "error",
              title: this.$t('errorTitle'),
              text: response.data.message || "ไม่สามารถทำรายการได้ในขณะนี้",
              type: "error",
            });
          }
        })
        .catch((error) => {
          // กรณีที่มีข้อผิดพลาดในการเรียก API
          console.error("เกิดข้อผิดพลาดในการเติมเครดิต:", error);
          this.$swal({
            icon: "error",
            title: this.$t('errorTitle'),
            text: "ไม่สามารถทำรายการได้ในขณะนี้",
            type: "error",
          });
        });
    },

    checkLogin() {
      if (!this.getMemberProfile) {
        return this.$swal({
          icon: "error",
          title: this.$t('errorTitle'),
          text: this.$t('coinTopup.validSignup'),
          type: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            return this.$router.push("/signin");
          }
        });
      }
    },
    copyBank(payment) {
      if (payment != "credit") {
        this.copyToClipboard(payment.account_no);
      }
    },
    copyToClipboard(bank_number, text = null) {
      try {
        const el = document.createElement("textarea");
        el.value = bank_number;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: text ? text :  this.$t('coinTopup.bankCopy'),
        });
      } catch (error) {}
    },
    goToBuy(param) {
      return this.$router.push({
        name: "GameTopUpSingle",
        params: { game_id: param },
      });
    },
    //
    async selectPackages(listPackage) {
      if (this.selectPackage === listPackage.id) {
        this.selectPackage = null;
        this.checkInput = false;
        this.topup.creadit_total_topup = "";
        this.confirm = false;
        this.selectedPaymentMethod = null;
      } else {
        this.selectPackage = listPackage.id;
        this.topup.creadit_total_topup = Number(listPackage.price).toFixed(2);
        try {
          await this.$store.dispatch("genQrCode", {
            amount: this.topup.creadit_total_topup,
          });

          // รับค่าของ QR code
          const qrCodeSvg = this.$store.getters.getQrCode;
          this.qrCodeImage = qrCodeSvg;
        } catch (error) {
          console.error("An error occurred while generating QR code:", error);
        }
        this.confirmDigit();
      }
    },
    selectPromotion(listPromotion) {
      if (this.selectedPromotion === listPromotion._id) {
        this.selectedPromotion = null;
        this.checkInput = false;
        this.topup.creadit_total_topup = "";
        this.confirm = false;
        this.topup.promotion_credit_id = "";
      } else {
        if (this.selectedPaymentMethod == null) {
          this.confirm = false;
        } else {
          this.confirm = true;
        }
        this.selectedPromotion = listPromotion._id;
        this.selectPackage = null;
        this.topup.creadit_total_topup = listPromotion.price;
        this.confirmDigit();
        this.$refs.myScroll.scrollIntoView({ behavior: "smooth" });
        this.topup.promotion_credit_id = listPromotion._id;
      }
    },
    showAlert() {
      if (this.selectPackage !== null) {
        this.$swal({
          icon: "warning",
          title: this.$t('coinTopup.rolePackage'),
          confirmButtonText: this.$t('submit'),
        }).then(() => {
          this.checkInput = true;
        });
      }
    },
    formatMoney() {
      this.confirm = false;
      this.topup.creadit_total_topup = "";
    },
    checkFocus() {
      if (this.selectPackage !== null) {
        return this.showAlert();
      } else {
        this.topup.creadit_total_topup = "";
        this.qrCodeImage = null;
        this.confirm = false;
      }
    },
    showInput() {
      this.selectPackage = null;
      this.selectedPromotion = null;
      this.confirm = false;
      this.topup.creadit_total_topup = "";
    },
    resetMoney() {
      this.creadit_total_topup = "";
      this.selectPackage = null;
      this.confirm = false;
    },
    async validateDecimal(event) {
      const value = event.target.value;
      // อนุญาตเฉพาะตัวเลขและจุดทศนิยม
      const newValue = value.replace(/[^\d.]/g, "");
      // ป้องกันการใส่จุดทศนิยมซ้ำ
      const parts = newValue.split(".");
      if (parts.length > 2) {
        parts.pop();
      }
      this.confirm = true;
      this.inputForm = true;
      event.target.value = parts.join(".");
      this.topup.creadit_total_topup = event.target.value;
      this.confirmDigit();

      // เรียกใช้ genQrCode และรอให้ทำงานเสร็จ
      try {
        await this.$store.dispatch("genQrCode", {
          amount: this.topup.creadit_total_topup,
        });

        // รับค่าของ QR code
        const qrCodeSvg = this.$store.getters.getQrCode;
        this.qrCodeImage = qrCodeSvg;
      } catch (error) {
        console.error("An error occurred while generating QR code:", error);
      }
    },
    downloadQRCode() {
      // เพิ่มคลาสอนิเมชั่นเมื่อเริ่มการดาวน์โหลด
      this.animationClass = true;

      // ใช้ DOMParser เพื่อแปลง HTML string เป็น DOM node
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.qrCodeImage, "image/svg+xml");
      const svgBlob = new Blob(
        [new XMLSerializer().serializeToString(doc.documentElement)],
        { type: "image/svg+xml" }
      );
      this.downloadUrl = URL.createObjectURL(svgBlob);

      // คลิกลิงก์เพื่อดาวน์โหลด
      const link = this.$refs.downloadLink;
      link.href = this.downloadUrl;
      link.click();

      // รีเซ็ต download URL
      URL.revokeObjectURL(this.downloadUrl);

      // ลบคลาสอนิเมชั่นหลังจากการดาวน์โหลดเสร็จ
      setTimeout(() => {
        this.animationClass = false;
      }, 1000); // ใช้เวลาตามที่ต้องการให้อนิเมชั่นเสร็จสิ้น
    },
    togglePackage(item) {
      item.showPackage = !item.showPackage;
    },
    formatDate(dateString) {
      const months = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear() + 543; // เพิ่ม 543 ปีเพื่อแปลงเป็นปีพุทธศักราช

      return `${day} ${month} ${year}`;
    },
    selectPaymentMethod(payment, method) {
      this.selectedPaymentMethod = method;
      this.confirmDigit();

      // ทำการโหลดข้อมูลที่เกี่ยวข้องกับวิธีการชำระเงินที่เลือก
      if (method === "bank") {
        // โหลดข้อมูลธนาคาร
        this.dataBank = payment[0];
        this.topup.bank_id = payment[0]._id;
      } else if (method === "wallet") {
        // โหลดข้อมูลวอลเล็ต
        this.dataWallet = payment[1];
        this.topup.bank_id = payment[1]._id;
      }
    },
    async downloadQRCodeTrue() {
      // ตรวจสอบว่า QrCodeTrue ถูกต้อง
      if (this.QrCodeTrue) {
        // กำหนด URL สำหรับดาวน์โหลด
        this.downloadUrlTrue = this.QrCodeTrue;

        // ตั้งชื่อไฟล์ที่ต้องการดาวน์โหลด
        this.$refs.downloadLinkTrue.download = 'qrcode.jpg';

        // รอให้ URL ถูกกำหนดอย่างสมบูรณ์ก่อนคลิกที่ลิงก์
        await new Promise(resolve => setTimeout(resolve, 100));

        // คลิกที่ลิงก์เพื่อเริ่มการดาวน์โหลด
        this.$refs.downloadLinkTrue.click();
      } else {
        console.error('QR Code URL is not set.');
      }
    },
    async getCountryFromIP() {
      if(this.language){
        if(this.language == 'ph'){
          this.country = 'php'
        } else if (this.language == 'th'){
          this.country = null
        }
        await this.fetchBanks(this.country);
        await this.fetchPromotions(this.country);
      }else{
        await this.fetchBanks(null);
        await this.fetchPromotions(null);
      }
    }
  },
};
</script>

<style scoped lang="scss">
// .container {
//     max-width: 960px;
// }

.input-wrapper {
  position: relative;
}
.input-wrapper .question-mark {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  pointer-events: none;
  background-color: rgb(255, 247, 0);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
}
h3 {
  color: white;
  margin-top: 5px;
  font-size: 1.8rem;
}
.box {
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  text-align: center;
  background-color: #333333ec;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.boxNumber {
  width: 30px;
  height: 30px;
  background-color: palevioletred;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}
.editMoney {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  background-color: #999;
  border-radius: 5px;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  cursor: pointer;
}
.selectPay {
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  color: white;
  background-color: #999;
  border-radius: 5px;
  text-align: center;
  padding: 3px;
  font-size: 14px;
}
.mobile-display {
  display: none;
}
.recommended-tag {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(45deg, #ff6b6b, #feca57);
  color: white;
  padding: 3px 8px;
  font-size: 0.6em;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: blink 1.5s ease-in-out infinite;
  border-radius: 0 0 0 10px;
  text-transform: uppercase;
}
.page-link {
  background-color: transparent;
}
.btn-unactive {
  background: linear-gradient(45deg, #e00a88 20%, #e00b60 80%);
  opacity: 0.8;
}
.btn-active {
  background: linear-gradient(45deg, #ec008c, #fc6767);
  transform: scale(1.04);
}
.promotion-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.coins-original {
  font-size: 0.9em;
  margin-bottom: 3px;
}

.coins-bonus {
  color: #4eff14;
  font-weight: bold;
  font-size: 0.9em;
}
.submit-pro {
  background-color: rgba(77, 75, 75, 0.394);
  cursor: pointer;
}

.icon-game {
  object-fit: cover;
  height: 100%;
}

.select-payment {
  border: 1px solid #e00b60;
}
.payment-method-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}
.payment-method-card span{
  font-size: 14px;
}

.payment-method-card:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.payment-method-card.selected {
  background-color: rgba(224, 11, 96, 0.5);
  border: 2px solid #e00b60;
}

.payment-method-card i {
  font-size: 24px;
  color: white;
  margin-bottom: 5px;
}

.payment-method-card span {
  display: block;
  color: white;
}

.check-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #e00b60;
}

strong{
  font-size: 18px;
}
.box > span {
  font-size: 14px;
}
.mt-5 .text-lg{
  font-size: 16px !important;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

.animate-download {
  animation: clickAnimation 0.5s ease-out;
}

/* Animation keyframes */
@keyframes clickAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.special-con {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 15px;
  padding: 10px 0px 10px 10px;
  transition: all 0.3s ease;
}

.special-con:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.shiny-effect::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  animation: shine 2s infinite ease-in-out;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

.use-rights-btn {
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: transparent !important;
}

.package-details {
  padding: 10px;
  margin-top: 15px;
  color: white;
  border-radius: 15px;
}

.package-details p {
  margin: 10px 0;
  font-size: 14px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.text-table{
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .mobile-display {
    display: block;
  }
  .desktop-display {
    display: none !important;
  }
  .mobile-setting {
    width: 100px !important;
    padding: 5px !important;
  }
  .mobile-side {
    padding: 24px !important;
  }
  .w-70 {
    width: 100% !important;
  }
  strong{
    font-size: 20px;
  }
  .fs-5{
    font-size: 16px !important;
  }
  .box {
    padding: 10px 5px;
  }
  .mb-4{
    margin-bottom: 10px !important;
  }
  .box > span {
    font-size: 10px;
  }
  .fix-amount{
    font-size: 10px !important;
    margin-bottom: 10px !important;
  }
  .fs-6{
    font-size: 12px !important;
  }
  .btn-text{
    font-size: 14px !important;
  }
  .package-details{
    margin-top: 0;
  }
  .package-details p,
  .package-details span,
  .package-details button {
    font-size: 10px !important;
  }
  .form-control{
    font-size: 14px !important;
  }
  .btn-lg{
    font-size: 14px !important;
  }
  .btn-xs{
    font-size: 10px !important;
  }
  .page-item .page-link, .page-item span{
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
  .special-con{
    padding: 5px 0px 5px 5px
  }
  .special-con p, 
  .special-con span {
    font-size: 10px !important;
  }
  .form-control{
    font-size: 10px !important;
  }
  .payment-method-card{
    padding: 8px 5px;
  }
  .payment-method-card span,
  .payment-method-card i{
    font-size: 12px !important;
  }
  label{
    font-size: 12px !important;
  }
  .fs-4{
    font-size: 16px !important;
  }
  .text-light{
    font-size: 10px !important;
  }
  .mt-5 .text-lg{
    font-size: 12px !important;
  }
  .text-table{
    font-size: 12px;
  }
  .recommended-tag{
    font-size: 8px !important;
    padding: 1px 6px
  }
  .fs-5{
    font-size: 12px !important;
  }
}
@media screen and (max-width: 498px) {
  .pagination{
    margin-left: 5rem !important;
  }
}
</style>