<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div class="page-header relative">
    <div class="container px-0 mt-7">
      <MainSliderHome :banners="this.getSetting.page?.home_page?.banners" />
    </div>
  </div>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="title-highlight">
          <h1 class="text-white fs-5">{{ $t('news_promotions') }}</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container" v-if="this.getSetting.page.article.banners && this.getSetting.page.article.banners.length < 4">
    <div id="carouselExampleIndicators2" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators" v-if="getSetting.page.article.banners.length > 1">
        <li data-bs-target="#carouselExampleIndicators2" v-for="(slide, index) in getSetting.page.article.banners" :key="index" :data-bs-slide-to="index" :class="index == 0 ? 'active' : ''"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(slide, index) in getSetting.page.article.banners" :key="index" :class="index == 0 ? 'active' : ''">
          <a v-if="slide.url" :href="slide.url">
            <img class="d-block w-100" :src="slide.img_url" alt="">
          </a>
          <img v-else class="d-block w-100" :src="slide.img_url" alt="">
        </div>
      </div>
      <a v-if="getSetting.page.article.banners.length > 1" class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a v-if="getSetting.page.article.banners.length > 1" class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <div class="container">
    <div class="row" v-if="getArticle && getArticle.length">
      <div class="col-lg-4 col-md-6 mb-4" v-for="(article, index) in getArticle" :key="index">
        <div class="card blur-dark border cursor-pointer overflow-hidden" style="border: 1px solid #e00b60 !important;" @click="goToRead(article)">
          <img class="img-fluid article-cover" :src="article.cover_url" alt="">
          <div class="card-body">
            <h2 class="text-danger article-subject fs-6">{{ article.subject }}</h2>
            <p class="text-white short-description">{{ article.short_description }}</p>
            <time style="font-size: 12px;"><i class="fas fa-clock"></i> {{ moment(article.created_date_iso).format('DD/MM/YYYY') }}</time>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col text-center">
        <h3>ไม่พบบทความ</h3>
      </div>
    </div>
  </div>
  <div class="j-space my-3"></div>
  
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MainSliderHome from "@/views/pages/components/MainSliderHome.vue";


// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftButton from "@/components/SoftButton.vue";

import { mapMutations, mapGetters, mapActions } from "vuex";
import AOS from "aos";
import 'aos/dist/aos.css';
import Rellax from 'rellax';
import moment from "moment";

export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    MainSliderHome
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
  },
  data() {
    return {
      moment: moment,
      language: localStorage.getItem("language") || 'th',
      country: null,
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.getCountryFromIP();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    AOS.init();
    if (document.getElementsByClassName('rellax')) {
      new Rellax('.rellax', {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true
      });

    //   rellax.destroy();
    }
  },
  computed: {
    ...mapGetters(['getSetting', 'getArticle'])
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['fetchArticle']),

    goToRead(param) {
      const sanitizedSubject = param.subject.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();
      // การสร้าง formatText
      const formatText = `เติมเกม${sanitizedSubject}${param._id}`;
      console.log('formatText: ', formatText);
      return this.$router.push(`/article/${formatText}`)
    },
    getCountryFromIP() {
      if(this.language){
        if(this.language == 'ph'){
          this.country = 'php'
        } else if (this.language == 'th'){
          this.country = null
        }
        let param = {
            page_index: 1,
            limit_list_per_page: 9,
            lang: this.country
          }
          this.fetchArticle(param);
      }else{
        let param = {
            page_index: 1,
            limit_list_per_page: 9,
            lang: null
          }
          this.fetchArticle(param);
      }
    }
  },
};
</script>

<style scoped lang="scss">
    img {
      &.article-cover {
        aspect-ratio: 4 / 3;
        object-fit: cover;
        object-position: center;
        margin: 0 auto;
        display: flex;
        max-height: 300px;
      }
    }
    .article-subject {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .short-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 50px;
      font-size: 12px;
    }
    @media screen and (max-width: 768px) {
      img {
        &.article-cover {
          aspect-ratio: unset;
          max-height: 150px;
          margin-top: 1rem;
        }
      }
      p{
        font-size: 10px !important;
      }
      time{
        font-size: 8px !important;
      }
    }
</style>